import { _converse } from "@converse/headless/core";
import { html } from "lit";
import { isThemeDracula } from "../../../utils/helper";


export default (o) => {
    return html`
    <div class="message date-separator" data-isodate="${o.time}">
        ${/*<hr class="separator"/>*/ ``}
        <time class="${isThemeDracula() ? "separator-text-hookzoffice" :"separator-text"}" datetime="${o.time}"><span>${o.datestring}</span></time>
    </div>
`
};
