import 'shared/avatar/avatar.js';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import { getPrettyStatus, logOut } from '../../../utils.js';
import { html } from "lit";
import { LOCAL_STORAGE, THEMES } from '../../../../../shared/constants.js';
import '../../../moment-listing.js';

import '../../../styles/moments.scss';
import { getCurrentTheme } from '../../../../../utils/helper.js';

function tpl_signout() {
    const i18n_logout = __('Log out');
    return html`<a class="controlbox-heading__btn logout align-self-center" title="${i18n_logout}" @click=${logOut}>
        <converse-icon class="fa fa-sign-out-alt" size="1em"></converse-icon>
    </a>`;
}

function tpl_user_settings_button(o) {
    const i18n_details = __('Show details about this chat client');
    return html`${api.settings.get('is_setting_icon_visible')
        ? `<a class="controlbox-heading__btn show-client-info align-self-center" title="${i18n_details}" @click=${o.showUserSettingsModal}>
        <converse-icon class="fa fa-cog" size="1em"></converse-icon>
    </a>`:""}`;
}   

export default (el) => {
    const chat_status = el.model.get('status') || 'offline';
    const fullname = JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).profile.nickName || el.model.vcard?.get('fullname') || el.model.vcard?.get('nickname') || JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).mobile || _converse.bare_jid;
    const status_message = el.model.get('status_message') || __("I am %1$s", getPrettyStatus(chat_status));
    const i18n_change_status = __('Click to change your chat status');
    const show_settings_button = api.settings.get('show_client_info') || api.settings.get('allow_adhoc_commands');
    let classes, color;
    if (chat_status === 'online') {
        [classes, color] = ['fa fa-circle chat-status', 'chat-status-online'];
    } else if (chat_status === 'dnd') {
        [classes, color] = ['fa fa-minus-circle chat-status', 'chat-status-busy'];
    } else if (chat_status === 'away') {
        [classes, color] = ['fa fa-circle chat-status', 'chat-status-away'];
    } else {
        [classes, color] = ['fa fa-circle chat-status', 'subdued-color'];
    }

    function renderMoments(model) {
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                return html``;

            case WINGTRILL_DARK:
                return html``;

            case DRACULA:
                return html`<converse-moment-listing .data=${model}></converse-moment-listing>`;

            default:
                return html`<converse-moment-listing .data=${model}></converse-moment-listing>`;
        }
    }

    return html
        `<div class="userinfo pb-0">
            <converse-chat-header></converse-chat-header>
            ${renderMoments(el.model)}
            <div class="controlbox-section profile d-none">
                <a class="show-profile" href="#" @click=${el.showProfileModal}>
                    <converse-avatar
                        class="avatar align-self-center"
                        .data=${el.model.vcard?.attributes}
                        nonce=${el.model.vcard?.get('vcard_updated')}
                        height="40"
                        width="40"
                    ></converse-avatar>
                </a>
                <span class="username w-100 align-self-center">${fullname}</span>
                <span class="profile-flex-icons"> ${show_settings_button ? tpl_user_settings_button(el) : ''}</span>
                <span class="profile-flex-icons"> ${api.settings.get('allow_logout') ? tpl_signout() : ''} </span>
            </div>
            <div class="d-none xmpp-status">
                <a
                    class="change-status"
                    title="${i18n_change_status}"
                    data-toggle="modal"
                    data-target="#changeStatusModal"
                    @click=${el.showStatusChangeModal}
                >
                    <span class="${chat_status} w-100 align-self-center" data-value="${chat_status}">
                        <converse-icon
                            color="var(--${color})"
                            style="margin-top: -0.1em"
                            size="0.82em"
                            class="${classes}"
                        ></converse-icon>
                        ${status_message}</span
                    >
                </a>
            </div>
        </div>`;
};
