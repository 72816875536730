import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';
import tpl_selected_occupants_list from "./selected-occupants-list.js"
import { uploadImageAndGetLink } from '@converse/headless/utils/serverApis.js';
import tps_contact_list from "./contact-list.js"

import '../styles/add-muclight.scss';

import { isThemeDracula } from '../../../../utils/helper.js';

const subject_input = (el) => {
    const i18n_subject = __('Subject');
    const i18n_required_field = __('This field is required');
        return html`
            <div class="form-group" >
                <label for="subject">${i18n_subject}:</label>
                <input type="text"
                    title="${i18n_required_field}"
                    required="required"
                    name="groupsubject"
                    value="${el.model.get('groupsubject') || ''}"
                    class="form-control"/>
            </div>
    `;
}

const nickname_input = (el) => {
    const i18n_nickname = __('Nickname');
    const i18n_required_field = __('This field is required');
        return html`
            <div class="form-group" >
                <label for="nickname">${i18n_nickname}:</label>
                <input type="text"
                    title="${i18n_required_field}"
                    required="required"
                    name="nickname"
                    value="${el.model.get('nick') || ''}"
                    class="form-control"/>
            </div>
    `;
}
  async function onFileSelection(ev,el) {
    // await el.model.save({ group_cover: ''});
    const files = ev.target.files[0];
    if (files?.type?.startsWith('image/')) {
        await el.model.save({alert: {
            type: 'info text-center',
            message: 'Uploading Cover Image.',
        }})
        const formData = new FormData();    //formdata object
        formData.append('image', files);   //append the values with key, value pair
        const fileUrl = await uploadImageAndGetLink(formData)
        if(fileUrl.success){
            // now send image to pubsub
            if(fileUrl?.data?.url) {
                await el.model.save({ group_cover: URL.createObjectURL(files),alert: {
                    type: 'success text-center',
                    message: 'Success, Cover Uploaded.',
                } })
                setTimeout(async() => {
                    await el.model.save({ alert: "" })
                }, 3000);
            }
        }
    }else{
        el.model.save({alert: {
            type: 'danger text-center',
            message: 'Please select a valid image.',
        }})
    }
}


export default (el) => {
    const i18n_join = __('Join');
    const muclight_domain = el.model.get('muclight_domain') || api.settings.get('muclight_domain');
    let placeholder = __('Group name');

    const label_room_address = muclight_domain ? __('Groupchat name') :  __('Groupchat Name');
    const muclight_roomid_policy_error_msg = el.muclight_roomid_policy_error_msg;
    const muclight_roomid_policy_hint = api.settings.get('muclight_roomid_policy_hint');
    const selected_occupants = el.model.get('group_occupants') || []
    const selected_occupants_list = _converse.roster.models.filter(c => selected_occupants.includes(c.get('jid')))
    let groupCover = el.model.get('group_cover');
    const isGroupAvatarUploaded = groupCover;
    if(!groupCover){
        if(isThemeDracula()){
            groupCover = '/images/hookz-office/avatar.svg';
        }else{
            groupCover = "/images/group-icons/upload-photo.svg";
        }
    }
    const isAvatarUploading = el.model.get('alert')?.message === 'Uploading Cover Image.';
    return html`${el.model.get('group_screen')===0 ? tps_contact_list(el,CHAT_TYPE.GROUP_CHAT) : html `
    <div class="d-flex align-items-center justify-content-center">
        <div class="position-relative">
            ${isGroupAvatarUploaded && !isAvatarUploading
            ? html`<div role="button" class="remove-avatar" @click=${() => {
                el.model.save({ group_cover: ''})
            }}>
                <img src="/images/general_assets/cross.svg" alt="remove" style="width: 30px;" />
            </div>`
            : ''}
            ${isAvatarUploading ? html`<div class="loader my-5"></div>`: html`<label class="group-cover-icon" for="group_cover" @click=${() => {
                const imageRef = document.getElementById('group_cover_input');
                if(!isGroupAvatarUploaded && !isAvatarUploading){
                    imageRef.click();
                }
            }}>
                <img 
                    style="width: 160px; height: 160px; object-fit: cover; cursor: ${!el.model.get('group_cover') ? 'pointer': 'default'}"
                    src=${groupCover}
                    alt="upload-photo"
                />
            </label>`}
        </div>
        <input type="file" @change=${ (ev) => onFileSelection(ev,el)} id="group_cover_input" name="image" style="display: none;" />
        ${!isGroupAvatarUploaded && !isAvatarUploading ? 
            html`<label class="group-cover-camera-icon position-absolute" style="top: ${el.model.get('alert') ? '30%': '18%'};" for="group_cover">
                    <img src=${isThemeDracula() ? "/images/hookz-office/camera.svg": "/images/group-icons/camera.svg"} alt="camera" />
                </label>`: ''}
        </div>
        ${!isAvatarUploading? html`<div class="d-flex justify-content-center align-items-center py-4 px-5 ${isThemeDracula() ? 'font-color-dracula': ''}" style="font-size: 1rem;">${isGroupAvatarUploaded ? "Group icon": "Add group icon"}</div>`: ''}
        <div class="d-flex align-items-center justify-content-center px-5" style="background: ${isThemeDracula() ? "#2B2B2B": "#F6F6F6"};">
            <input
                type="text"
                placeholder="Group Name"
                name="groupname"
                @keyup=${ (ev) => el.model.save({group_name: ev.target.value})}
                value="${el.model.get('group_name') || ''}"
                class="${isThemeDracula() ? "search-contact-dracula": "search-contact"} w-100 p-0"
                style="height: 4rem; font-size: 1rem;"
            />
            <img src="${isThemeDracula() ? "/images/hookz-office/emoji.svg": "/images/group-icons/emoji.svg"}" alt="emoji" />
        </div>
        <div class="w-100 px-5 mt-2 py-4 d-flex flex-column" style="background: ${isThemeDracula() ? "#2B2B2B": "#F6F6F6"};">
            <textarea
            style="${isThemeDracula() ? 'color: #BBBBBB;' : ''}"
            class="${isThemeDracula() ? "desc-textarea-dracula": "desc-textarea"} w-100" placeholder="Description"
            @keyup=${ (ev) => el.model.save({group_description: ev.target.value})}
            >${el.model.get('group_description') || ''}</textarea>
            <span class="${isThemeDracula() ? 'font-color-dracula': ''}" style="margin-left: auto;">0/85</span>
        </div>
        <div class="py-4 px-5 ${isThemeDracula() ? 'font-color-dracula': ''}" style="padding-left: 1rem;">Participant(s) (${selected_occupants_list.length})</div>
        <div class="px-5">
            <div class="selected-occupants-list-container d-flex text-center position-relative">
                <div @click=${ async (ev) => await el.model.save({ group_screen: 0 })} class="selected-occupants-list-sub-container" role="button">
                    <div class="selected-occupant-avatar d-flex flex-column align-items-center">
                    ${isThemeDracula()
                        ? html`<img src="/images/hookz-office/add.svg" alt="add" style="width: 57px;" />`
                        : html`<svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="57.403"
                              height="57.403"
                              viewBox="0 0 57.403 57.403"
                          >
                              <g
                                  id="Group_17020"
                                  data-name="Group 17020"
                                  transform="translate(15211.685 2134.58)"
                              >
                                  <g
                                      id="Group_17018"
                                      data-name="Group 17018"
                                      transform="translate(-15211.685 -2134.58)"
                                  >
                                      <circle
                                          id="Ellipse_553"
                                          data-name="Ellipse 553"
                                          cx="28.701"
                                          cy="28.701"
                                          r="28.701"
                                          fill="#f6f6f6"
                                      />
                                  </g>
                                  <path
                                      id="Icon_ionic-ios-add"
                                      data-name="Icon ionic-ios-add"
                                      d="M25.91,16.875H19.125V10.09a1.125,1.125,0,0,0-2.25,0v6.785H10.09a1.125,1.125,0,0,0,0,2.25h6.785V25.91a1.125,1.125,0,0,0,2.25,0V19.125H25.91a1.125,1.125,0,0,0,0-2.25Z"
                                      transform="translate(-15200.983 -2123.878)"
                                      fill="#007dfc"
                                  />
                              </g>
                          </svg>`}    
                          <span class="${isThemeDracula() ? 'font-color-dracula': ''}">Add</span>           
                    </div>
                </div>
                ${selected_occupants_list.length ? html `
                        ${tpl_selected_occupants_list(selected_occupants_list,el)}` : ``}
            </div>
        </div>
        ${muclight_roomid_policy_hint
            ? html`<div class="form-group">
                  ${unsafeHTML(DOMPurify.sanitize(muclight_roomid_policy_hint, { 'ALLOWED_TAGS': ['b', 'br', 'em'] }))}
              </div>`
            : ''}
        ${api.settings.get('locked_muclight_nickname') ? nickname_input(el) : ''}
        ${
            // api.settings.get('subject_input_visibility')
            false ? subject_input(el) : ''
        }
    `}`
}
