import tpl_invite_member_to_workstation from "./templates/themes/dracula/invite-member-to-workstation.js";
import tpl_invite_member_to_workstation_footer from "./templates/themes/dracula/invite-member-to-workstation_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class InviteMemberToWorkstationModal extends BaseModal {

    initialize () {
        super.initialize();
        this.model = _converse.xmppstatus
        this.listenTo(this.model, 'add', () => this.render());
        this.listenTo(this.model, 'change', () => this.render());
        this.render();
    }

    renderModal () {
        return tpl_invite_member_to_workstation(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(`Invite`);
    }
    renderModalFooter() {
        return tpl_invite_member_to_workstation_footer(this);
    }

}

api.elements.define('converse-invite-member-to-workstation-modal', InviteMemberToWorkstationModal);
