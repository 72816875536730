import tpl_group from '../../../group.js';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import { contactsComparator, groupsComparator } from '@converse/headless/plugins/roster/utils.js';
import { html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { shouldShowContact, shouldShowGroup, populateContactsMap } from '../../../../utils.js';

import '../../../../styles/roster_dracula.scss';
import {
    DRACULA_CHAT_TABS,
    DRACULA_SIDE_BAR_TAB_ARCHIVES,
    DRACULA_SIDE_BAR_TAB_DMS,
    DRACULA_SIDE_BAR_TAB_DRAFTS,
    DRACULA_SIDE_BAR_TAB_GROUPS,
    MUCLIGHT,
    DRACULA_CHAT_HEADER_CHAT,
    DRACULA_CHAT_HEADER_CALL,
    DRACULA_CALL_TABS,
    DRACULA_CALL_ALL_LOGS,
} from '../../../../../../shared/constants.js';
import { isEmpty, isNull, isUndefined } from 'lodash-es';

import tpl_call_listing from '../../dracula/call_listing_dracula.js';
import Helper from '../../../../../../utils/helper.js';

export default el => {
    const i18n_heading_contacts = __('Contacts');
    const i18n_toggle_contacts = __('Click to toggle contacts');
    const i18n_title_add_contact = __('Add a contact');
    const i18n_title_sync_contacts = __('Re-sync your contacts');
    const roster = _converse.roster || [];
    roster?.models?.sort((a, b) => a.get(`stamp`) - b.get(`stamp`)).reverse();
    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const groupnames = Object.keys(contacts_map).filter(shouldShowGroup);
    const is_closed = el.model.get('toggle_state') === _converse.CLOSED;
    groupnames.sort(groupsComparator);
    let activeTab = el?.activeTab;

    const chatHeaderActiveTab = el?.chatHeaderActiveTab || DRACULA_CHAT_HEADER_CHAT;

    let tabs = DRACULA_CHAT_TABS;

    const isActiveChatHeaderTabCall = chatHeaderActiveTab === DRACULA_CHAT_HEADER_CALL;
    const isActiveChatHeaderTabChat = chatHeaderActiveTab === DRACULA_CHAT_HEADER_CHAT;

    if (isActiveChatHeaderTabCall) {
        tabs = DRACULA_CALL_TABS;
    }

    if (!activeTab) {
        if (isActiveChatHeaderTabCall) {
            activeTab = DRACULA_CALL_ALL_LOGS;
        } else {
            activeTab = DRACULA_SIDE_BAR_TAB_DMS;
        }
    } else {
        if (isActiveChatHeaderTabChat && !DRACULA_CHAT_TABS.includes(activeTab)) {
            activeTab = DRACULA_SIDE_BAR_TAB_DMS;
        } else if (isActiveChatHeaderTabCall && !DRACULA_CALL_TABS.includes(activeTab)) {
            activeTab = DRACULA_CALL_ALL_LOGS;
        }
    }

    function openCity(tabName) {
        api.trigger('refresh_contact_list_for_archived', tabName);
    }

    function getActiveClass(tab) {
        if (activeTab === tab) {
            return 'active';
        }
        return '';
    }

    function getDisplayClass(tab) {
        if (activeTab === tab) {
            return 'd-block';
        }
        return 'd-none';
    }

    return html`
        <div class="controlbox-padded d-none">
            <span class="w-100 controlbox-heading controlbox-heading--contacts">
                <a class="list-toggle open-contacts-toggle" title="${i18n_toggle_contacts}" @click=${el.toggleRoster}>
                    <converse-icon
                        class="fa ${is_closed ? 'fa-caret-right' : 'fa-caret-down'}"
                        size="1em"
                        color="var(--chat-color)"
                    ></converse-icon>
                    ${i18n_heading_contacts}
                </a>
            </span>
            <a
                class="controlbox-heading__btn sync-contacts"
                @click=${ev => el.syncContacts(ev)}
                title="${i18n_title_sync_contacts}"
            >
                <converse-icon
                    class="fa fa-sync right ${el.syncing_contacts ? 'fa-spin' : ''}"
                    size="1em"
                ></converse-icon>
            </a>
            ${api.settings.get('allow_contact_requests')
                ? html` <a
                      class="controlbox-heading__btn add-contact "
                      @click=${ev => el.showAddContactModal(ev)}
                      title="${i18n_title_add_contact}"
                      data-toggle="modal"
                      data-target="#add-contact-modal"
                  >
                      <converse-icon class="fa fa-user-plus right" size="1.25em"></converse-icon>
                  </a>`
                : ''}
        </div>

        <div class="mt-2 mt-xl-3 list-container roster-contacts ${is_closed ? 'hidden' : ''}">
            ${api.settings.get('is_notification_text_div_visible')
                ? html` <div class="d-flex tab w-100 justify-content-xl-between px-2 px-xl-3">
                          ${tabs.map(tab => {
                              const updatedTab = tab.replaceAll('_', ' ');
                              return html`<button
                                  class="d-flex align-items-center px-1 px-xxl-2 py-2 tablinks ${getActiveClass(updatedTab)}"
                                  @click="${() => openCity(updatedTab)}"
                              >
                                  ${updatedTab}
                                  ${updatedTab !== 'Drafts' && updatedTab !== 'Archives' && updatedTab !== 'External'
                                      ? html`<span class="tab-number ml-1">2</span>`
                                      : ''}
                              </button>`;
                          })}
                      </div>
                      ${isActiveChatHeaderTabCall
                          ? tpl_call_listing(el, groupnames)
                          : html`<div class="tabcontent ${getDisplayClass(activeTab)}">
                                ${repeat(
                                    groupnames,
                                    n => n,
                                    name => {
                                        if (name === 'New messages') {
                                            return;
                                        }
                                        let contacts = contacts_map[name].filter(c => shouldShowContact(c, name));
                                        // contacts = _converse.roster_filter.get(`list_type`)===`archived` ? contacts.filter(function (el) {
                                        //     return el.get(`is_archived`)===true
                                        // }
                                        // ) : contacts.filter(function (el) {
                                        //     return el.get(`is_archived`)!==true
                                        // }
                                        // )
                                        if (activeTab === DRACULA_SIDE_BAR_TAB_DMS) {
                                            contacts = contacts.filter(function (el) {
                                                return (
                                                    el.get(`is_archived`) !== true &&
                                                    el
                                                        .get(`jid`)
                                                        .includes(`@${Strophe.getDomainFromJid(_converse.bare_jid)}`)
                                                );
                                            });
                                        } else if (activeTab === DRACULA_SIDE_BAR_TAB_ARCHIVES) {
                                            contacts = contacts.filter(function (el) {
                                                return el.get(`is_archived`) === true;
                                            });
                                        } else if (activeTab === DRACULA_SIDE_BAR_TAB_DRAFTS) {
                                            contacts = contacts.filter(function (el) {
                                                return (
                                                    !isNull(_converse?.chatboxes?.get(el?.get(`jid`))?.get('draft')) &&
                                                    !isEmpty(_converse?.chatboxes?.get(el?.get(`jid`))?.get('draft')) &&
                                                    !isUndefined(
                                                        _converse?.chatboxes?.get(el?.get(`jid`))?.get('draft')
                                                    ) &&
                                                    _converse?.chatboxes?.get(el?.get(`jid`))?.get('draft')?.length > 0
                                                );
                                            });
                                        } else if (activeTab === DRACULA_SIDE_BAR_TAB_GROUPS) {
                                            contacts = contacts.filter(function (el) {
                                                return (
                                                    el.get(`is_archived`) !== true &&
                                                    el.get(`jid`).includes(`@${MUCLIGHT.DOMAIN}`)
                                                );
                                            });
                                        }
                                        // contacts.sort(contactsComparator);
                                        return contacts.length ? tpl_group({ contacts, name }, el) : '';
                                    }
                                )}
                            </div>`}`
                : ''}
        </div>
    `;
};
