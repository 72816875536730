import { __ } from 'i18n';
import { html } from "lit";


export const modal_close_button =
    html`<button type="button" class="btn btn-light text-dark mt-2 mr-3 add-grp-btn" data-dismiss="modal" style="width: 20%;">${__('Cancel')}</button>`;

export const modal_header_close_button =
    html`<button type="button" class="close" data-dismiss="modal" aria-label="${__('Close')}"><span aria-hidden="true">×</span></button>`;

export const custom_modal_header_close_button =
    html`<button type="button" class="btn btn-dark btn-circle mr-4" data-dismiss="modal" aria-label="${__('Close')}"><span aria-hidden="true">×</span></button>`;

export const close_modal_header_btn_dracula = 
    html`<div
        role="button"
        data-dismiss="modal"
        aria-label="${__('Close')}"
    >
        <img src="/images/hookz-office/close-modal.svg" alt="close" />
    </div>`;
