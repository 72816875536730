import tpl_group from '../../group.js';
import tpl_scheduled_calls from './scheduled_calls.js';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import { html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { shouldShowContact, shouldShowGroup, populateContactsMap } from '../../../utils.js';

import '../../../styles/roster_dracula.scss';
import { DRACULA_CALL_ALL_LOGS, DRACULA_CALL_SCHEDULED_CALLS } from '../../../../../shared/constants.js';
import { isUniView } from '@converse/headless/utils/core.js';

import { toggleGroup } from '../../../utils.js';
import { roster_section_edit } from '@converse/headless/plugins/chat/utils.js';

export default (el, groupnames) => {
    const i18n_title = __('Click to hide these contacts');
    const roster = _converse.roster || [];
    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const activeTab = el?.activeTab || DRACULA_CALL_ALL_LOGS;
    const collapsed = _converse.roster.state.get('collapsed_groups');

    const isScheduledCallsActive = activeTab === DRACULA_CALL_SCHEDULED_CALLS;

    if(isScheduledCallsActive){
        return tpl_scheduled_calls();
    }

    function triggered_data(jid) {
        api.trigger('refresh_contact_list',jid);
    }

    function renderContact (contact) {
        if(contact.get(`hidden`) || contact.get('jid')===_converse.bare_jid || (_converse.roster_filter.get(`roster_section_filter`) && !contact.get('num_unread'))) {
            // return ``
        }
        const jid = contact.get('jid');
        const extra_classes = [];
        if (isUniView()) {
            const chatbox = _converse.chatboxes.get(jid);
            if (chatbox && !chatbox.get('hidden')) {
                extra_classes.push('open-hookzoffice');
            }
        }
        const ask = contact.get('ask');
        const requesting  = contact.get('requesting');
        const subscription = contact.get('subscription');
        if ((ask === 'subscribe') || (subscription === 'from')) {
            /* ask === 'subscribe'
             *      Means we have asked to subscribe to them.
             *
             * subscription === 'from'
             *      They are subscribed to us, but not vice versa.
             *      We assume that there is a pending subscription
             *      from us to them (otherwise we're in a state not
             *      supported by converse.js).
             *
             *  So in both cases the user is a "pending" contact.
             */
            extra_classes.push('pending-xmpp-contact');
        } else if (requesting === true) {
            extra_classes.push('requesting-xmpp-contact');
        } else if (subscription === 'both' || subscription === 'to') {
            extra_classes.push('current-xmpp-contact');
            extra_classes.push(subscription);
            extra_classes.push(contact.presence.get('show'));
        }
        const selectedJids = _converse.roster_filter.get('selected_jids') || [];
        const isChecked = selectedJids.includes(contact.get('jid'));
       
        return html`
            <div class="position-relative">
                <li
                    class="list-item d-flex controlbox-padded ${extra_classes.join(' ')}"
                    data-status="${contact.presence.get('show')}"
                >
                    ${_converse.roster_filter.get(`roster_section_edit`)
                            ? html`<div style="padding: 10px;">
                                <input
                                    type="checkbox"
                                    @click=${() => {
                                        triggered_data(jid);
                                    }}
                                    jid=${jid}
                                    name="roster_section_edit[]"
                                    .checked=${isChecked}
                                    class="checkbox-dracula"
                                />
                            </div>`
                        : ``}
                    <converse-roster-contact .model=${contact}></converse-roster-contact>
                </li>
                <hr class="contact-hr" />
            </div>
        `;
    }

    return html`${repeat(
        groupnames,
        n => n,
        name => {
            if(name === "New messages") {
                return;
            }
            const contacts = contacts_map[name].filter(c => shouldShowContact(c, name));
            return contacts.length ? html` <div class="roster-group" data-group="${name}">
            <a
                href="#"
                class="list-toggle group-toggle controlbox-padded d-none"
                title="${i18n_title}"
                @click=${ev => toggleGroup(ev, name)}
            >
                <converse-icon
                    color="var(--chat-head-color-dark)" 
                    size="1em"
                    class="fa ${collapsed.includes(name) ? 'fa-caret-right' : 'fa-caret-down'}"
                ></converse-icon>
                ${name}
            </a>
            ${_converse.roster_filter.get(`roster_section_edit`)
                ? html`<div
                      class="d-flex align-items-center justify-content-between px-4 mt-2"
                      style="background: "#1D1D1D"; height: 50px; width: 100%;"
                  >
                      <div class="d-flex align-items-center justify-content-between" style="width: 36%;">
                          <img
                              src="/images/chat-sidebar/cross.svg"
                              alt="close"
                              class="cursor-pointer"
                              @click=${() => {
                                  _converse.roster_filter.save({
                                      roster_section_edit: !_converse.roster_filter.get(`roster_section_edit`),
                                      selected_jids: [],
                                      roster_section_edit_dropdown: false,
                                  });
                              }}
                          />
                          <div>${_converse.roster_filter.get(`selected_jids`)?.length || 'No'} chat(s) selected</div>
                      </div>
                      <div
                          class="d-flex align-items-center h-75"
                          role="button"
                          @click=${() => {
                              _converse.roster_filter.save({
                                  roster_section_edit_dropdown:
                                      !_converse.roster_filter.get(`roster_section_edit_dropdown`),
                              });
                          }}
                      >
                          <img src="/images/chat-sidebar/options.svg" alt="options" />
                      </div>
                  </div>`
                : ``}
            <ul
                class="items-list roster-group-contacts ${collapsed.includes(name) ? 'collapsed' : ''}"
                data-group="${name}"
            >
                ${_converse.roster_filter.get(`roster_section_edit_dropdown`)
                    ? html`<form class="converse-form roster_section_edit position-relative" style="padding: 0 !important;">
                          <div class="editDropdown-dracula">
                              <div
                                  class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                                  role="button"
                              >
                                  <input
                                      class="editOptions-dracula"
                                      @click=${roster_section_edit}
                                      type="button"
                                      name="read"
                                      value="Read All"
                                  />
                                  <img src="/images/hookz-office/read-all.svg" alt="read-all" />
                              </div>
                              <div
                                  class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                                  role="button"
                              >
                                  <input
                                      class="editOptions-dracula"
                                      @click=${roster_section_edit}
                                      type="button"
                                      name="archive"
                                      value="${_converse.roster_filter.get(`list_type`) === `archived`
                                          ? `Unarchived`
                                          : `Archive`}"
                                  />
                                  <img src="/images/hookz-office/archive.svg" alt="archive" />
                              </div>
                              <div
                                  class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                                  role="button"
                              >
                                  <input
                                      class="editOptions-dracula"
                                      @click=${roster_section_edit}
                                      type="button"
                                      name="delete"
                                      value="Delete"
                                  />
                                  <img src="/images/hookz-office/delete.svg" alt="delete" />
                              </div>
                          </div>
                          ${repeat(contacts, c => c.get('jid'), renderContact)}
                      </form>`
                    : repeat(contacts, c => c.get('jid'), renderContact)}
                    ${/*repeat(o.contacts, (c) => c.get('jid'), renderContact)*/ ``}
            </ul>
        </div>` : '';
        }
    )}`;
};
