import tpl_chat_popups_wingtrill_light from "./templates/themes/wingtrill/light/chat-popups.js";
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core.js';
import { getCurrentTheme } from "../../utils/helper.js";
import { BOT_JID, THEMES } from "../../shared/constants.js";

import './styles/chat-popups.scss';

class ChatPopups extends CustomElement {

    async initialize() {
        api.listen.on('updateChatPopups', ({ jid, shouldRemove }) => {
            this.updateChatPopups({ jid, shouldRemove })
        })
    }

    updateChatPopups({ jid, shouldRemove }) {
        if (!jid) return;
        if (shouldRemove) {
            _converse.chatPopupsJids = _converse.chatPopupsJids?.filter(item => item !== jid);
        } else {
            if (!Array.isArray(_converse.chatPopupsJids)) {
                _converse.chatPopupsJids = [jid];
            } else if (!_converse.chatPopupsJids.includes(jid)) {
                _converse.chatPopupsJids.push(jid);
            }
        }
        this.requestUpdate();
    }

    render() { // eslint-disable-line class-methods-use-this
        if (!Array.isArray(_converse.chatPopupsJids) || !_converse.chatPopupsJids.length) return;
        const { WINGTRILL_LIGHT } = THEMES;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                template = tpl_chat_popups_wingtrill_light(_converse.chatPopupsJids);
                break;

            default:
                template = tpl_chat_popups_wingtrill_light(_converse.chatPopupsJids);
        }
        return template;
    }
}

api.elements.define('converse-chat-popups', ChatPopups);
