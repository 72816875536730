import { html } from 'lit';
import { __ } from 'i18n';
import { _converse } from '@converse/headless/core';
import { repeat } from 'lit/directives/repeat.js';

export default (jids) => {
    return html
        `<div class="d-flex h-100">${repeat(jids, jid => jid, jid => html`<converse-chat jid="${jid}" .isPopup=${true} class="chatbox d-flex"></converse-chat>`
        )}${''
    //     <div class="d-flex flex-column-reverse avatars">${repeat(jids, jid => jid, jid => html`<converse-avatar class="avatar" .data=${_converse.roster?.get(jid)?.attributes} height="150" width="150"></converse-avatar>`
    // )}</div>
    }</div>`
};