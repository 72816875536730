import tpl_initiate_new_chat from "./templates/themes/dracula/initiate-new-chat.js";
import tpl_initiate_new_chat_footer from "./templates/themes/dracula/initiate-new-chat_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class InitiateNewChatModal extends BaseModal {

    initialize () {
        super.initialize();
        this.model = _converse.xmppstatus
        this.listenTo(this.model, 'add', () => this.render());
        this.listenTo(this.model, 'change', () => this.render());
        this.render();
    }

    renderModal () {
        return tpl_initiate_new_chat(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(`Initiate a new chat`);
    }
    renderModalFooter() {
        return tpl_initiate_new_chat_footer(this);
    }

}

api.elements.define('converse-initiate-new-modal', InitiateNewChatModal );
