import tpl_outgoing_call from "./templates/outgoing-call.js";
import tpl_outgoing_call_footer from "./templates/outgoing-call_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import './styles/outgoing-call.scss';
import { playCallRingSound, stopCallRingSound } from "@converse/headless/utils/serverApis.js";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class OutgoingCallModal extends BaseModal {

    initialize () {
        super.initialize();
        _converse.outgoingcallmodal = this
        this.render();
    }

    renderModal () {
        return tpl_outgoing_call(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(``);
    }
    renderModalFooter() {
        return tpl_outgoing_call_footer(this);
    }
    Calling() {
        playCallRingSound()
        _converse.xmppstatus.get(`call`)[this.model.get('jid')].CallingTimmer = setTimeout( () => {
            // this.stopCall()
        },55000)
    }
    stopCall() {
        this.chatbox.sendMessage(this.cancelcalldata)
        _converse.xmppstatus.get(`call`)[this.model.get('jid')]?.stream?.getTracks()?.forEach(function (track) {
            track.stop();
        });
        if(_converse.xmppstatus.get(`call`)[this.model.get('jid')]?.remoteStream?.getTracks()) {
            _converse.xmppstatus.get(`call`)[this.model.get('jid')]?.remoteStream?.getTracks()?.forEach(function (track) {
                track.stop();
            });
        }
        if(_converse.xmppstatus.get(`call`)[this.model.get('jid')]) {
            _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc ? _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc.close() : ``
            _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc ? _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc = null : ``
        }

        stopCallRingSound()
        this.modal.hide()
    }

}

api.elements.define('converse-outgoing-call-modal', OutgoingCallModal);
