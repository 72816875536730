import { CustomElement } from 'shared/components/element.js';
import tpl_moment_listing from './templates/moment-listing.js';
import tpl_moment_listing_dracula from './templates/themes/dracula/moment-listing.js';
import { _converse, api, converse } from '@converse/headless/core';
import { html } from 'lit';
import { MESSAGE_TYPE } from '@converse/headless/plugins/muclight/constants.js';
import { isThemeDracula } from '../../utils/helper.js';

export default class MomentListing extends CustomElement {
    static get properties() {
        return {
            data: { type: Object },
            width: { type: String },
            height: { type: String },
            nonce: { type: String }, // Used to trigger rerenders
        };
    }

    constructor() {
        super();
        this.width = 36;
        this.height = 36;
    }

    initialize() {
        api.waitUntil('rosterContactsFetched').then( () => {
            this.model = _converse.story || []
            this.listenTo(this.model, 'add', () => this.requestUpdate());
            this.listenTo(this.model, 'destroy', () => this.requestUpdate());
            this.listenTo(this.model, 'remove', () => this.requestUpdate());
            this.listenTo(this.model, 'change:story_id', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:drop_down', () =>  this.requestUpdate());    
            this.requestUpdate()
        });

    }

    render() {
        this.storyDestroyingByTimeManagement();
        if (!this.model) return ``;
        return isThemeDracula() ? tpl_moment_listing_dracula(this) : tpl_moment_listing(this);
    }
    storyDestroyingByTimeManagement() {
        clearInterval(_converse.storyDestroying)
            _converse.storyDestroying = setInterval(() => {
                if(this.model) {
                    this.model.models.map(data=> {
                        const data_stories = data.get('stories')
                        let mystories = data.get('stories')
                        data_stories.map(datas => {
                        const body = JSON.parse(datas.body)
                        const storyExpiry = parseInt(body.postDate)+24 * 3600*1000
                        const nowTime = Date.now()
                        const index = data_stories.indexOf(datas)
                        if(storyExpiry-new Date(nowTime)<=0) {
                            mystories.splice(index,1)
                        }
                    })
                    mystories.length>0 
                    ? data.save({stories:mystories,...mystories[mystories.length-1]})
                    : data.destroy()
                })
                }        
        }, 1000*10);
    }
}

api.elements.define('converse-moment-listing', MomentListing);
