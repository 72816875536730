import { html } from "lit";
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";

import '../../../styles/roster_filter_dracula.scss';
import { DRACULA_CHAT_HEADER_CHAT } from "../../../../../shared/constants";

export default (o, el) => {
    const i18n_placeholder = __('Search');
    const title_contact_filter = __('Filter by contact name');
    const title_group_filter = __('Filter by group name');
    const title_status_filter = __('Filter by status');
    const label_any = __('Any');
    const label_unread_messages = __('Unread');
    const label_online = __('Online');
    const label_chatty = __('Chatty');
    const label_busy = __('Busy');
    const label_away = __('Away');
    const label_xa = __('Extended Away');
    const label_offline = __('Offline');

    const chatHeaderActiveTab = el?.chatHeaderActiveTab || DRACULA_CHAT_HEADER_CHAT;
    const isChatTabActive = chatHeaderActiveTab === DRACULA_CHAT_HEADER_CHAT;

    return html` <form
        class="p-2 p-xl-3 controlbox-padded roster-filter-form input-button-group ${!o.visible ? '' : 'fade-in'}"
        @submit=${o.submitFilter}
    >
        <div class="d-flex justify-content-between">
            <button
                @click=${() => {
                    _converse.roster_filter.save({ roster_section_edit: !o.roster_section_edit });
                }}
                class="d-flex align-items-center px-2 px-xl-3 py-1 py-xl-2 edit-filter-btn"
            >
                <img src="/images/hookz-office/edit-icon.svg" alt="edit" class="mr-1 w-25" />

                <span class="btn-text">${o.roster_section_edit ? `Cancel` : `Edit`}</span>
            </button>
            ${isChatTabActive
                ? html`<button
                      class="d-flex align-items-center px-2 px-xl-3 py-1 py-xl-2 edit-filter-btn"
                      @click=${() => {
                          _converse.roster_filter.save({ roster_section_filter: !o.roster_section_filter });
                      }}
                  >
                      <img src="/images/hookz-office/filter-icon.svg" alt="filter" class="mr-1 w-25" />
                      <span class="btn-text"
                          >${_converse.roster_filter.get(`roster_section_filter`) ? 'All' : 'Filter'}</span
                      >
                  </button>`
                : ''}
        </div>
    </form>`;
};
