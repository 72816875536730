import { html } from 'lit';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import '../styles/switch-workstations.scss';
const { u } = converse.env;
import { logOut } from '../../profile/utils';

function generategradient() {
    var hexValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e'];

    function populate(a) {
        for (var i = 0; i < 6; i++) {
            var x = Math.round(Math.random() * 14);
            var y = hexValues[x];
            a += y;
        }
        return a;
    }

    var newColor1 = populate('#');
    var newColor2 = populate('#');
    var angle = Math.round(Math.random() * 360);

    var gradient = 'linear-gradient(' + angle + 'deg, ' + newColor1 + ', ' + newColor2 + ')';
    return gradient;
}

export default el => {
    const getCreateWorkStationUrl = () => {
        window.location.href = window.location.href.includes('http://localhost')
            ? 'http://localhost:3000/workstation-registration'
            : `https://${window.location.hostname.replace('web', 'www')}/workstation-registration`;
    };

    function handleMouseEnter(data) {
        return () => {
            api.trigger('showHubTooltip', data);
        };
    }

    function hideToolTip() {
        api.trigger('showHubTooltip');
    }

    function handleCopy(string, hubId) {
        return () => {
            navigator.clipboard
                .writeText(string)
                .then(() => {
                    api.trigger('showHubTooltip', { hoveredHubId: hubId, isTextCopied: true });
                })
                .catch(error => {
                    console.error('Error copying text to clipboard:', error);
                });
        };
    }

    return html`${el.model.models.map(data => {
            return html`<div
                class="d-flex p-2"
                @mouseenter=${handleMouseEnter({ hoveredHubId: data.get('id') })}
                @mouseleave=${hideToolTip}
            >
                <div
                    class="work-station-block ${el.modeldata.get('selected_workstation') === data.get('id')
                        ? `work-station-block-selected`
                        : ``}"
                    @click=${async ev => {
                        u.hideAllChatBox();
                        await el.modeldata.save({ selected_workstation: data.get('id') });
                        await _converse.roster.switchWorkstationRosters();
                        await _converse.roster.fetchFromServer();
                        api.trigger('allWorkstationsData');
                    }}
                >
                    <div class="work-station-block-text position-relative" style="background: ${data.get('color') || generategradient()};">
                        <span>${data.get('name').slice(0, 2)}</span>
                        ${data.get('id') === el.hoveredHubId
                            ? html`<span class="position-absolute hub-tooltip p-1 p-xxl-2">
                                  <span
                                      class="d-flex align-items-center p-1 hub-name-font-size hub-name-option hub-name-option-width"
                                  >
                                      <span class="p-1 dropdown-avatar" style="background: ${data.get('color') || generategradient()};"
                                          >${data.get('name').slice(0, 2)}</span
                                      >
                                      <span class="ml-2">${data.get('name')}</span>
                                  </span>
                                  <span
                                      class="d-flex align-items-center justify-content-between hub-tooltip-dropdown-option p-1 option-font-size"
                                      @click=${handleCopy(data.get('name'), data.get('id'))}
                                  >
                                      <span> ${el.isTextCopied ? 'Copied!' : 'Copy'} </span>
                                      <span class="ml-2 ml-lg-3 ml-xxl-4">
                                          <img src="/images/hookz-office/copy.svg" alt="copy" />
                                      </span>
                                  </span>
                                  <span
                                      class="d-flex align-items-center justify-content-between hub-tooltip-dropdown-option p-1 option-font-size"
                                      @click=${() => {
                                          logOut();
                                          hideToolTip();
                                      }}
                                  >
                                      <span class="hub-name-option hub-name-option-width">
                                          Sign out of ${data.get('name')}
                                      </span>
                                      <span class="ml-2 ml-lg-3 ml-xxl-4">
                                          <img src="/images/hookz-office/sign-out.svg" alt="sign out" />
                                      </span>
                                  </span>
                                  <span
                                      class="d-flex align-items-center justify-content-between p-1 option-font-size"
                                      @click=${ev => {
                                          api.modal.show(
                                              'converse-invite-member-to-workstation-modal',
                                              { 'model': el.model, ev },
                                              ev
                                          );
                                          hideToolTip();
                                      }}
                                  >
                                      <span> Invite members </span>
                                      <span class="ml-2 ml-lg-3 ml-xxl-4">
                                          <img src="/images/hookz-office/invite-add.svg" alt="invite" />
                                      </span>
                                  </span>
                              </span>`
                            : ''}
                    </div>
                </div>
            </div>`;
        })}
        <div class="d-flex mt-3">
            <div class="work-station-create-block" @click=${getCreateWorkStationUrl}>
                <div class="work-station-create-block-text">+</div>
            </div>
        </div> `;
};
