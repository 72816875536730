import DropdownBase from './dropdownbase.js';
import { api } from "@converse/headless/core.js";
import { html } from 'lit';
import { until } from 'lit/directives/until.js';

import './styles/hookzoffice-dropdown.scss';


export default class HookzOfficeDropdown extends DropdownBase {

    static get properties () {
        return {
            'items': { type: Array }
        }
    }

    constructor () {
        super();
        this.icon_classes = 'fa fa-angle-down';
    }

    render () {
        return html`
            <button type="button" class="btn btn--transparent p-1 d-flex">
                <img src="/images/hookz-office/chat-header/arrow.svg" alt="more-options" />
            </button>
            <div class="dropdown-menu" role="button">
                ${this.items.map(b => until(b, ''))}
            </div>`;
    }
}

api.elements.define('converse-hookzoffice-dropdown', HookzOfficeDropdown);