import { CustomElement } from 'shared/components/element.js';
import tpl_avatar from './templates/avatar.js';
import tpl_avatar_dracula from './templates/themes/dracula/avatar.js';
import tpl_avatar_wingtrill_light from './templates/themes/wingtrill/light/avatar.js';
import tpl_avatar_wingtrill_dark from './templates/themes/wingtrill/dark/avatar.js';
import { _converse, api } from '@converse/headless/core';
import { THEMES } from '../../shared/constants';
import { Strophe } from 'strophe.js/src/strophe';
import { getCurrentTheme } from '../../utils/helper.js'

import './avatar.scss';

export default class Avatar extends CustomElement {
    static get properties() {
        return {
            data: { type: Object },
            width: { type: String },
            height: { type: String },
            nonce: { type: String }, // Used to trigger rerenders
        };
    }

    constructor() {
        super();
        this.width = 36;
        this.height = 36;
    }

    render() {
        const image_type = this.data?.image_type || _converse.DEFAULT_IMAGE_TYPE;
        let image;
        if (this.data?.data_uri) {
            image = this.data?.data_uri;
        } else {
            const image_data = this.data?.image || _converse.DEFAULT_IMAGE;
            image = image_data.startsWith('https://')
                    ? image_data
                    : 'data:' + image_type + ';base64,' + image_data;
        }
        const isDefaultImage = image.includes('DEFAULT_IMAGE');
        const let_domain = this?.data?.jid ? Strophe.getDomainFromJid(this?.data?.jid) : this?.data?.jid;
        const isGroup = let_domain === Strophe.NS.MUCLIGHT_DOMAIN;
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        const name = this.data?.nickname;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                if (isDefaultImage) {
                    if(isGroup){
                        image = `/images/default-group-icon/${Math.floor(Math.random() * 5)}.png`;
                    } else {
                        image = `/images/hookz-office/default-avatars/${Math.floor(Math.random() * 4)}.svg`;
                    }
                }
                template = tpl_avatar_wingtrill_light({
                    'classes': this.getAttribute('class'),
                    'height': this.height,
                    'width': this.width,
                    image,
                    image_type,
                    isDefaultImage,
                    name
                });
                break;

            case WINGTRILL_DARK:
                if (isDefaultImage) {
                    if(isGroup){
                        image = `/images/default-group-icon/${Math.floor(Math.random() * 5)}.png`;
                    } else {
                        image = `/images/hookz-office/default-avatars/${Math.floor(Math.random() * 4)}.svg`;
                    }
                }
                template = tpl_avatar_wingtrill_dark({
                    'classes': this.getAttribute('class'),
                    'height': this.height,
                    'width': this.width,
                    image,
                    image_type,
                    isDefaultImage,
                    name
                });
                break;

            case DRACULA:
                if (isDefaultImage) {
                    if(isGroup){
                        image = `/images/default-group-icon/${Math.floor(Math.random() * 5)}.png`;
                    } else {
                        image = `/images/hookz-office/default-avatars/${Math.floor(Math.random() * 4)}.svg`;
                    }
                }
                template = tpl_avatar_dracula({
                    'classes': this.getAttribute('class'),
                    'height': this.height,
                    'width': this.width,
                    image,
                    image_type,
                });
                break;

            default:
                if (isDefaultImage) {
                    if(isGroup){
                        image = `/images/default-group-icon/${Math.floor(Math.random() * 5)}.png`;
                    } else {
                        image = `/images/default-avatars/${Math.floor(Math.random() * 4)}.png`;
                    }
                }
                template = tpl_avatar({
                    'classes': this.getAttribute('class'),
                    'height': this.height,
                    'width': this.width,
                    image,
                    image_type,
                });
        }
        return template;
    }
}

api.elements.define('converse-avatar', Avatar);
