import tpl_call_connected from "./templates/call-connected.js";
import tpl_call_connected_footer from "./templates/call-connected_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import './styles/call-connected.scss';

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class CallConnected extends BaseModal {

    initialize () {
        super.initialize();
        _converse.callconnectedmodal = this
        this.render();
    }

    renderModal () {
        return tpl_call_connected(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(``);
    }
    renderModalFooter() {
        return tpl_call_connected_footer(this);
    }
    leaveCall(sendMessage=true) {
        if(sendMessage) {
            this.chatbox.sendMessage(_converse.xmppstatus.get(`call`)[this.model.get('jid')].leave_call)
        }
        _converse.xmppstatus.get(`call`)[this.model.get('jid')]?.stream?.getTracks()?.forEach(function (track) {
            track.stop();
        });
        if(_converse.xmppstatus.get(`call`)[this.model.get('jid')]?.remoteStream?.getTracks()) {
            _converse.xmppstatus.get(`call`)[this.model.get('jid')]?.remoteStream?.getTracks()?.forEach(function (track) {
                track.stop();
            });
        }
        if(_converse.xmppstatus.get(`call`)[this.model.get('jid')]) {
            _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc ? _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc.close() : ``
            _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc ? _converse.xmppstatus.get(`call`)[this.model.get('jid')].pc = null : ``
        }

        this.modal.hide()
    }

}

api.elements.define('converse-call-connected-modal', CallConnected);
