import tpl_main from "./templates/themes/wingtrill/light/modal/doctor-appointment.js";
import BaseModal from "plugins/modal/modal.js";
import { _converse, api } from "@converse/headless/core";
import { html } from "lit";
import { getSelectedDateAppointments, getThisWeekDates } from "@converse/headless/utils/serverApis.js";

export default class DoctorAppointmentModal extends BaseModal {
    constructor() {
        super();
        this.isFetchingWeekDates = true;
        this.weekDates = [];
        this.isDateSelected = false;
        this.isFetchingAppointments = true;
        this.appointments = [];
        this.errors = [];
        this.showModalHeader = false;
    }

    async initialize () {
        super.initialize();
        this.fetchWeekDates();
    }

    renderModal () {
        return tpl_main(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return html``;
    }

    renderModalFooter() {
        return html``;
    }

    async fetchWeekDates(){
        try {
            const response = await getThisWeekDates();
            const jsonParsedResponse = JSON.parse(response);
            this.isFetchingWeekDates = false;
            if (Array.isArray(jsonParsedResponse)) {
                this.weekDates = jsonParsedResponse;
            }
        } catch(error) {
            console.error(error)
            this.errors = [...this.errors, error?.message];
        } finally {
            this.render();
        }
    }

    async fetchAppointments(payload){
        try {
            this.isDateSelected = true;
            this.isFetchingAppointments = true;
            this.render();
            const response = await getSelectedDateAppointments(payload);
            const jsonParsedResponse = JSON.parse(response);
            this.isFetchingAppointments = false;
            if (Array.isArray(jsonParsedResponse)) {
                this.appointments = jsonParsedResponse;
            }
        } catch(error){
            console.error(error);
            this.errors = [...this.errors, error?.message];
        } finally {
            this.render();
        }
    }
}

api.elements.define('converse-doctor-appointment-modal', DoctorAppointmentModal);
