import { html } from 'lit';
import '../../../styles/scheduled_calls.scss';

export default () => {
    return html`<div class="scheduled-calls">
        <div class="scheduled-call mt-2 p-2 p-xl-3 p-xxxl-5">
            <div class="d-flex justify-content-between"><span>Design - Audio</span><span>- John Doe</span></div>
            <div class="d-flex justify-content-between secondary-font-size py-1 py-lg-2 py-xxxl-3">
                <span>Design - Audio</span><span>14-06-2022</span>
            </div>
            <div class="row secondary-font-size d-flex align-items-center">
                <div class="col-sm-12 col-xl-4 pr-0">
                    <span>02:30PM - 03:30PM</span>
                </div>
                <div class="col-sm-12 col-xl-8 d-flex">
                    <button class="view-details secondary-font-size mr-1">View Details</button>
                    <button class="join-call secondary-font-size">Join call</button>
                </div>
            </div>
        </div>
    </div>`;
};
