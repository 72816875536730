import { _converse } from '@converse/headless/core.js';
import { html } from 'lit';
import { __ } from 'i18n';
import { CALL_TYPE } from '@converse/headless/plugins/muclight/constants';

export default el => {
    el.Calling();
    return html`
        <header>
            <div class="d-flex justify-content-between px-5 py-4 bg-secondary-color">
                <span class="d-flex align-items-center">
                    <img class="bluetooth-icon" src="/images/hookz-office/call/hamburger.svg" alt="record" />
                    <span class="ml-2">${el.model.get('nickname')}</span>
                </span>
                <span class="d-flex align-items-center">
                    <span class="mr-2">Add Participants</span
                    ><span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                        <img class="bluetooth-icon" src="/images/hookz-office/call/add-participants.svg" alt="record" />
                    </span>
                </span>
            </div>
        </header>
        <div class="outgoing-call-main-container">
            <div class="outgoing-call-sub-container">
                <div class="outgoing-recipient-name">${el.model.get('nickname')}</div>
                <div class="outgoing-call-type">${el.CALL_TYPE_VALUE} Call</div>
                <div class="outgoing-recipient-avatar">
                    <converse-avatar
                        class="avatar"
                        .data=${el.model.attributes}
                        height="150"
                        width="150"
                    ></converse-avatar>
                </div>
            </div>
        </div>
        <footer>
            <div class="d-flex justify-content-between px-5 py-4 bg-secondary-color">
                <div class="d-flex">
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/speaker.svg" alt="record" />
                        </span>
                        <span class="mt-1">Speaker</span>
                    </span>
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/bluetooth.svg" alt="record" />
                        </span>
                        <span class="mt-1">Bluetooth</span>
                    </span>
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/record.svg" alt="record" />
                        </span>
                        <span class="mt-1">Record</span>
                    </span>
                </div>
                <div class="d-flex">
                    <span class="mute-unmute-video-call-color common-icon-wrapper mid-icons-wrapper mr-3">
                        <img src="/images/hookz-office/call/unmute.svg" alt="record" />
                    </span>
                    <span class="mute-unmute-video-call-color common-icon-wrapper mid-icons-wrapper">
                        <img class="video-call-icon" src="/images/hookz-office/call/video.svg" alt="record" />
                    </span>
                    <span
                        class="end-call-color common-icon-wrapper mid-icons-wrapper ml-3"
                        @click=${async ev => {
                            el.stopCall();
                        }}
                        >
                        <img class="end-call-icon" src="/images/hookz-office/call/end-call.svg" alt="record" />
                    </span>
                </div>
                <div class="d-flex">
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/share-screen.svg" alt="record" />
                        </span>
                        <span class="mt-1">Share</span>
                    </span>
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/participants.svg" alt="record" />
                        </span>
                        <span class="mt-1">Participants</span>
                    </span>
                    <span class="d-flex flex-column align-items-center mx-2">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/chat.svg" alt="record" />
                        </span>
                        <span class="mt-1">Chat</span>
                    </span>
                </div>
            </div>
        </footer>
    `;
};
