import 'shared/components/font-awesome.js';
import { api } from '@converse/headless/core';
import { html } from 'lit';
import '../../../../styles/wingtrill/light/root.scss';
import { logOut } from '../../../../../profile/utils';

export default el => {
    const extra_classes = api.settings.get('singleton') ? ['converse-singleton'] : [];
    extra_classes.push(`converse-${api.settings.get('view_mode')}`);
    const workstation = el?.model?.get({ id: el?.modeldata?.get('selected_workstation') });

    function handleTogglePauseNotifications(e) {
        api.trigger('showPauseNotificationsDropdown', !el.showPauseNotificationsDropdown);
    }

    function handleMouseLeave(e) {
        api.trigger('showPauseNotificationsDropdown', false);
    }

    const allUserAccounts = [
        {
            id: 1,
            name: 'Abdulla Ahmad',
            email: 'abdulla@wingtrill.com',
        },
        {
            id: 2,
            name: 'Kashish Gandhi',
            email: 'kashish@wingtrill.com',
        },
        {
            id: 3,
            name: 'Manjit Singh',
            email: 'manjit@wingtrill.com',
        },
    ];

    function renderSearchIcon() {
        return html`<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="none"
            stroke="#242424"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>`;
    }

    function renderSeperationLine() {
        return html`<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1 24"
            width="1"
            height="24"
            fill="none"
            stroke="#242424"
            stroke-width="1"
        >
            <line x1="0.5" y1="0" x2="0.5" y2="24" />
        </svg>`;
    }

    function renderBellIcon() {
        return html`<i class="fa-solid fa-bell" style="color:#114A7A;"></i>`;
    }

    function renderDownArrow() {
        return html` <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="15"
            height="15"
            fill="none"
            stroke="#242424"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path d="M6 9l6 6 6-6" />
        </svg>`;
    }

    return html`
            <div class="d-flex wingtrill-main-header px-3 py-2 align-items-center">
            <div class="col-md-3 col-sm-12 d-flex align-items-center p-0"></div>
            <div class="col-md-6 col-sm-12">
                <div class="d-flex align-items-center search-bar p-1 p-xl-2">
                    <span class="ml-3"> ${renderSearchIcon()} </span>
                    <input type="text" class="search-input ml-2" placeholder="Search" />
                    <span class="d-flex align-items-center mr-3">
                        ${renderSeperationLine()}
                        <span class="mx-2">${renderBellIcon()}</span>
                        <span class="turn-on-notifications">Turn on notifications</span>
                    </span>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 d-flex align-items-center p-0 position-relative flex-row-reverse">
                <span
                    class="cursor-pointer mr-5"
                    @click=${() => api.trigger('showUserAccountDropdown', !el.showUserAccountDropdown)}
                >
                    <converse-avatar height="40px" width="40px"></converse-avatar>
                    <span class="avatar-name">Aditya Kumar</span>
                    ${renderDownArrow()}
                </span>
                ${el.showUserAccountDropdown
                    ? html`<div class="user-profile-dropdown-menu p-2 p-lg-3 p-xl-4">
                        <span class="d-flex align-items-center py-2 py-xl-3 btrlr-15">
                            <converse-avatar height="50px" width="50px"></converse-avatar>
                            <span class="d-flex flex-column ml-2 ml-xxl-3">
                                <span class="d-flex align-items-center">
                                    <span class="user-name-font-size">John Doe</span>
                                    <img class="ml-1 admin" src="/images/hookz-office/admin.svg" alt="admin" />
                                </span>
                                <span class="sections-font-size">Active</span>
                            </span>
                        </span>
                        <section class="d-flex flex-column my-1">
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section admin-console sections-font-size btrlr-15"
                            >
                                Admin console
                            </span>
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-online-status sections-font-size"
                            >
                                Set yourself as away
                            </span>
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 pr-2 pr-lg-3 pr-xl-4 py-1 py-lg-2 py-xl-3 bbrlr-15 change-notifications d-flex justify-content-between align-items-center sections-font-size"
                                @click=${handleTogglePauseNotifications}
                                >Pause notifications</span
                            >
                        </section>
                        <section class="d-flex flex-column my-1">
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 btrlr-15 menu-section profile sections-font-size"
                            >
                                Profile
                            </span>
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section preferences sections-font-size"
                            >
                                Preferences
                            </span>
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 bbrlr-15 upgrade sections-font-size"
                            >
                                Upgrade ${workstation?.get('name') || ``}</span
                            >
                        </section>
                        <section class="d-flex flex-column my-1">
                            <span class="accounts-wrapper">
                                ${allUserAccounts.map((account, index) => {
                                    const isFirstItem = index === 0;
                                    if (!isFirstItem && !el.showAllUserAccountsDropdown) return '';
                                    return html`<span
                                        class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 align-items-center menu-section sign-out ${isFirstItem
                                            ? 'btrlr-15'
                                            : ''} d-flex"
                                    >
                                        <converse-avatar height="30px" width="30px"></converse-avatar>
                                        <span class="d-flex align-items-center justify-content-between w-100">
                                            <span class="d-flex flex-column ml-2">
                                                <span class="sections-font-size">Sign out of ${account.name}</span>
                                                <span class="email-font-size mt-1">${account.email}</span>
                                            </span>
                                            ${isFirstItem
                                                ? html`<converse-icon
                                                        size="1em"
                                                        class="fa fa-angle-${el.showAllUserAccountsDropdown
                                                            ? 'up'
                                                            : 'down'} fa-bars mr-2 mr-lg-3 mr-xl-4"
                                                        @click=${() => {
                                                            api.trigger(
                                                                'showAllUserAccountsDropdown',
                                                                !el.showAllUserAccountsDropdown
                                                            );
                                                        }}
                                                    ></converse-icon>`
                                                : ''}
                                        </span>
                                    </span>`;
                                })}
                            </span>
                            <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section sign-out d-flex">
                                <img src="/images/hookz-office/add.svg" alt="add" width="30" />
                                <span class="d-flex align-items-center justify-content-between w-100">
                                    <span class="d-flex flex-column ml-2">
                                        <span class="sections-font-size">Add another account</span>
                                    </span>
                                </span>
                            </span>
                            <span
                                class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 sign-out bbrlr-15 d-flex"
                                @click=${logOut}
                            >
                                <img src="/images/hookz-office/sign-out-round.svg" alt="add" width="30" />
                                <span class="d-flex align-items-center justify-content-between w-100">
                                    <span class="d-flex flex-column ml-2">
                                        <span class="sections-font-size">Sign out of all accounts</span>
                                    </span>
                                </span>
                            </span>
                        </section>
                        ${el.showPauseNotificationsDropdown
                            ? html`<div class="pause-notifications-dropdown-menu sections-font-size p-2 p-xl-3">
                                    <span><small>Mute notifications for...</small></span>
                                    <span class="durations-wrapper mt-2">
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section admin-console d-flex flex-column btrlr-15"
                                        >
                                            <span class="mt-1">For 30 minutes</span>
                                        </span>
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-online-status"
                                        >
                                            For 1 hour
                                        </span>
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 pr-2 pr-lg-3 pr-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-notifications d-flex justify-content-between align-items-center"
                                            ><span>For 2 hour</span></span
                                        >
                                        <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section profile">
                                            Until tomorrow
                                        </span>
                                        <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 preferences bbrlr-15">
                                            Custom
                                        </span>
                                    </span>
                                </div>`
                            : ''}
                    </div>`
                    : ''}
            </div>
        </div>
        <div class="chat-section">
            <converse-chats class="converse-chatboxes row no-gutters ${extra_classes.join(' ')}"></converse-chats>
            <div class="wingtrill-light">
                <div class="wingtrill-light-home overflow-auto">
                    <div class="wingtrill-light-home-innerdiv">
                        <div class="container">
                            <div class="d-flex mb-3 align-items-center justify-content-between">
                            <span class="d-flex align-items-center">
                                <converse-avatar height="75px" width="75px"></converse-avatar>
                                <span class="primary-heading ml-3">Welcome to Wingtrill, Aditya Kumar</span>
                            </span>
                            <span class="share-profile px-2 py-1 px-xl-4 py-xl-3 secondary-heading">
                                <span>Share profile</span>
                                <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="18.693" height="15.764" viewBox="0 0 18.693 15.764">
                                    <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M22.035,12.94l-6.5-6.08a.332.332,0,0,0-.258-.111c-.2.009-.461.152-.461.368v3.049a.2.2,0,0,1-.166.189C8.19,11.346,5.468,16.182,4.505,21.2c-.037.2.23.382.355.226a11.746,11.746,0,0,1,9.764-4.947.228.228,0,0,1,.193.221V19.7a.4.4,0,0,0,.668.244l6.545-6.186a.509.509,0,0,0,.161-.387A.617.617,0,0,0,22.035,12.94Z" transform="translate(-3.999 -6.249)" fill="none" stroke="#252525" stroke-width="1"/>
                                </svg>                                                                             
                            </span>
                        </div>
                        <div>
                            <div class="secondary-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                        </div>
                        <div class="row py-5 justify-content-around">
                            <div class="col-10 col-lg-5 d-flex flex-column p-1 card">
                                <div>
                                    <img
                                        class="w-100"
                                        src="/images/wingtrill/light/initiate-chat.svg"
                                        alt="initiate-chat"
                                    />
                                </div>
                                <div>
                                    <div class="p-3 secondary-heading">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>
                                    <div class="d-flex justify-content-center pb-3">
                                        <button
                                            type="button"
                                            class="px-3 px-md-4 px-xl-5 py-1 py-md-2 py-xl-3 card-btn"
                                            @click=${ev =>
                                                api.modal.show(
                                                    'converse-invite-member-to-workstation-modal',
                                                    { 'model': el.model, ev },
                                                    ev
                                                )}
                                        >
                                            Initiate a chat
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-10 col-lg-5 d-flex flex-column p-1 card">
                                <div>
                                    <img
                                        class="w-100"
                                        src="/images/wingtrill/light/place-a-call.svg"
                                        alt="place-a-call"
                                    />
                                </div>
                                <div>
                                    <div class="p-3 secondary-heading">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>
                                    <div class="d-flex justify-content-center pb-3">
                                        <button type="button" class="px-3 px-md-4 px-xl-5 py-1 py-md-2 py-xl-3 card-btn">Place a call</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="converse-modals" class="modals"></div>
            <converse-fontawesome></converse-fontawesome>
            <converse-chat-popups></converse-chat-popups>
        </div>
    `;
};
