import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";

import '../../../styles/add-muclight.scss';


export default (el,rosters, hideCheckBox) => {

    const selected_occupants = el.model.get('group_occupants') || []
    return rosters.contacts.map(dataRosters => {
             return html` <div
                 @click=${async ev => {
                    if(hideCheckBox){
                        dataRosters?.openChat();
                        el.modal.hide();
                    }else{
                            selected_occupants.includes(dataRosters.get(`jid`))
                            ? selected_occupants.indexOf(dataRosters.get(`jid`)) > -1
                                ? selected_occupants.splice(selected_occupants.indexOf(dataRosters.get(`jid`)), 1)
                                : ``
                            : selected_occupants.push(dataRosters.get(`jid`));
                        await el.model.save({ group_occupants: selected_occupants });
                        api.trigger('refresh_group_occupants');
                    }
                 }}
                 class="d-flex align-items-center w-100 p-3"
                 style="height: 60px;"
             >
                 <converse-avatar
                     class="avatar align-self-center"
                     .data=${dataRosters?.attributes?.image?.length
                         ? dataRosters?.attributes
                         : dataRosters?.vcard?.attributes}
                     nonce=${dataRosters?.attributes?.image?.length
                         ? dataRosters?.attributes
                         : dataRosters?.vcard?.attributes}
                     height="40"
                     width="40"
                 ></converse-avatar>
                 <div
                     class="d-flex align-items-center justify-content-between w-100 ml-1 mx-3"
                     style="height: 60px; border-bottom: 1px solid #818181;"
                 >
                     <label class="mb-0 mx-1" for="subject" style="font-size: 1rem;"
                         >${dataRosters.get(`nickname`) ? dataRosters.get(`nickname`) : dataRosters.get(`jid`)}</label
                     >
                     ${!hideCheckBox
                         ? selected_occupants.includes(dataRosters.get(`jid`))
                             ? html` <input
                                   type="checkbox"
                                   title="${dataRosters.get(`nickname`) || dataRosters.get(`jid`)}"
                                   style="width: 1rem; height: 1rem;"
                                   checked
                               />`
                             : html`<input
                                   type="checkbox"
                                   title="${dataRosters.get(`nickname`) || dataRosters.get(`jid`)}"
                                   style="width: 1rem; height: 1rem;"
                               />`
                         : ''}
                 </div>
             </div>`;
    });

}
