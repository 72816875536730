/**
 * @copyright 2022, the Converse.js contributors
 * @license Mozilla Public License (MPLv2)
 */
import './chat-popups.js';
import { _converse, converse } from '@converse/headless/core';

converse.plugins.add('converse-chat-popups', {
    dependencies: [],

    initialize() {
    }
});