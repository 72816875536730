import BaseModal from "plugins/modal/modal.js";
import log from "@converse/headless/log";
import tpl_moments_tagging from "../templates/moments_tagging.js";
import tpl_moments_tagging_footer from "../templates/moments_tagging_footer.js";
import tpl_moments_tagging_dracula from "../templates/themes/dracula/moments_tagging.js";
import tpl_moments_tagging_footer_dracula from "../templates/themes/dracula/moments_tagging_footer.js";
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import '../password-reset.js';
import { getData, setData } from "@converse/headless/utils/localStore.js";
import { uploadImageAndGetLink, userProfile } from "@converse/headless/utils/serverApis.js";
import { isThemeDracula } from "../../../utils/helper.js";

export default class MomentsTagging extends BaseModal {
    initialize() {
        super.initialize();
        this.listenTo(_converse.roompanel, 'change:group_occupants_search', () => this.render());
        api.listen.on('groupInfoRequestUpdate', () => {
            this.render();
        });
        api.listen.on('refresh_group_occupants', () => {
            this.render();
        });

        /**
         * Triggered when the _converse.MomentsTagging has been created and initialized.
         * @event _converse#momentsTaggingInitialized
         * @type { _converse.XMPPStatus }
         * @example _converse.api.listen.on('momentsTaggingInitialized', status => { ... });
         */
    }

    renderModal () {
        if (this.ev) {
            _converse.roompanel.save({
                group_occupants_search: '',
                group_screen: 0,
                group_name: '',
                group_description: '',
                group_cover: '',
                group_occupants: [],
                error: '',
                alert: '',
            });
            delete this.ev;
        }
        return isThemeDracula()
            ? tpl_moments_tagging_dracula(this.jsonData)
            : tpl_moments_tagging(this.jsonData);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(`Tag Friends${_converse.roompanel.get('group_occupants').length ? ` to  ${_converse.roster.get(_converse.roompanel.get('group_occupants')[0]).get('nickname')} ${_converse.roompanel.get('group_occupants').length>1 ? `and ${_converse.roompanel.get('group_occupants').length-1} more.` : ``}` : ``}`);
    }

    renderModalFooter() {
        return isThemeDracula() ? tpl_moments_tagging_footer_dracula(this) : tpl_moments_tagging_footer(this);
    }


    /*
    *   addParticipants will call server to execute or request to add new member in existing
    *   group
    *   Example to use : addParticipants(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member we wish to add in group
    */
        async tagMembers (el={}) {
            return new Promise(async (resolve, reject) => {
                try{
                    const jids = el.occupants
                    const tag_friends = []
                    const selected_friends = []
                    const storyTaggedFriend = []
                    for(const jid of jids) {
                        const friends = await _converse.roster.get(jid)
                        tag_friends[jid] = friends?.attributes ? friends?.attributes : []
                        selected_friends.push(jid)
                        storyTaggedFriend.push({
                            contactName:friends.get(`nickname`),
                            contactNumber: ``,
                            contactJid: jid
                        })
                    }
                    this.jsonData.tag_friends = tag_friends
                    this.jsonData.selected_friends = selected_friends
                    this.jsonData.storyTaggedFriend = storyTaggedFriend
                    api.trigger('moment-upload', this.jsonData)
                    this.modal.hide();
                    resolve(true)
                    }catch(error){
                    reject(error)
                }
            })
        }
}

api.elements.define('converse-moments-tagging', MomentsTagging);
