/**
 * @copyright 2022, the Converse.js contributors
 * @license Mozilla Public License (MPLv2)
 */
import './components/chat-header.js';
import { converse } from '@converse/headless/core';
import '../../shared/components/hookzoffice-dropdown.js';

import '../chatview/invite-member-to-workstation.js'
import '../chatview/initiate-new-chat.js'
import '../../shared/chat/doctor-appointment-modal.js'

converse.plugins.add('converse-chat-header', {
    dependencies: [],

    initialize () {
    }
});