import { html } from 'lit';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import '../../../styles/chat-header-dracula.scss';
import { DRACULA_CHAT_HEADER_CHAT, DRACULA_CHAT_HEADER_CALL, DRACULA_CHAT_HEADER_CALENDAR, DRACULA_CHAT_HEADER_CALLOUT } from '../../../../../shared/constants';
import { logOut } from '../../../../profile/utils';

export default (el) => {
    const getActionButtons = () => {
        let buttons = [];
        buttons.push({
            'i18n_text': "New Group",
            'handler': (ev) => api.modal.show('converse-add-muclight-modal', { 'model': el.model, ev }, ev),
            'imgSrc': '/images/hookz-office/chat-header/dropdown/new-group.svg'
        });
        buttons.push({
            'i18n_text': "New Contact",
            'handler': (ev) => '',
            'imgSrc': '/images/hookz-office/chat-header/dropdown/new-contact.svg'
        });
        buttons.push({
            'i18n_text': "New Call",
            'handler': (ev) => '',
            'imgSrc': '/images/hookz-office/chat-header/dropdown/new-call.svg'
        });
        buttons.push({
            'i18n_text': "New meeting",
            'handler': (ev) => '',
            'imgSrc': '/images/hookz-office/chat-header/dropdown/new-meeting.svg'
        });
        buttons.push({
            'i18n_text': "Invite",
            'handler': (ev) => api.modal.show('converse-invite-member-to-workstation-modal', { 'model': el.model, ev }, ev),
            'imgSrc': '/images/hookz-office/chat-header/dropdown/new-group.svg'
        });
        // buttons.push({
        //     'i18n_text': "Log Out",
        //     'handler': (ev) => logOut(),
        //     'imgSrc': '/images/hookz-office/chat-header/dropdown/new-group.svg'
        // });
        return buttons;
    }

    const getActionsDropdownItem = async (o) => {
        return html `
        <div class="d-flex px-4 py-3 dropdown-option" @click=${o.handler}>
            <img src="${o.imgSrc}" alt="" />
            <span class="ml-3">${o.i18n_text}</span>
        </div>`;
    }

    const buttons = getActionButtons();
    const items = buttons.map(b => getActionsDropdownItem(b));

    const activeTab = el?.activeTab || 'chat';

    function updateActiveTab(tab){
        api.trigger('updateTab', tab);
    }

    function getBackgroundClass(tab){
        if(tab === activeTab){
            return 'active-tab'
        }
        return 'custom-box-shadow-dracula';
    }

    return html`<div class="d-flex justify-content-end position-relative py-3">
        <div class="d-flex align-items-center btns-container pr-2 position-relative">
            <button
                @click=${ev => updateActiveTab(DRACULA_CHAT_HEADER_CHAT)}
                type="button"
                class="d-flex justify-content-center align-items-center btn btn-circle ${getBackgroundClass(DRACULA_CHAT_HEADER_CHAT)} icon mx-1"
            >
                <img src="/images/hookz-office/chat-header/chat.svg" alt="chat" />
            </button>
            <button
                @click=${ev => updateActiveTab(DRACULA_CHAT_HEADER_CALL)}
                type="button"
                class="d-flex justify-content-center align-items-center btn btn-circle ${getBackgroundClass(DRACULA_CHAT_HEADER_CALL)} icon mx-1"
            >
                <img src="/images/hookz-office/chat-header/call.svg" alt="call" />
            </button>
            <button
                @click=${ev => updateActiveTab(DRACULA_CHAT_HEADER_CALENDAR)}
                type="button"
                class="d-flex justify-content-center align-items-center btn btn-circle ${getBackgroundClass(DRACULA_CHAT_HEADER_CALENDAR)} icon mx-1"
            >
                <img src="/images/hookz-office/chat-header/calendar.svg" alt="calendar" />
            </button>
            <button
                @click=${ev => updateActiveTab(DRACULA_CHAT_HEADER_CALLOUT)}
                type="button"
                class="d-flex justify-content-center align-items-center btn btn-circle ${getBackgroundClass(DRACULA_CHAT_HEADER_CALLOUT)} icon mx-1"
            >
                <img src="/images/hookz-office/chat-header/call-out.svg" alt="call-out" />
            </button>
            <div class="d-flex align-items-center h-100 px-2 new-chat ml-1">
                <div class="d-flex align-items-center px-xxl-2 px-1" role="button" @click=${(ev) => api.modal.show('converse-initiate-new-modal', { 'model': el.model, ev }, ev)}>
                    <img src="/images/hookz-office/chat-header/new-chat.svg" alt="" />
                    <div class="ml-2 btn-new-chat d-none d-xl-block">New Chat</div>
                </div>
                <img src="/images/hookz-office/chat-header/vertical-line.svg" alt="-" height="25" />
                <converse-hookzoffice-dropdown
                    class="dropleft"
                    .items=${items}
                ></converse-hookzoffice-dropdown>
            </div>
        </div>
    </div>`;
};
