import tpl_switch_workstations from "../templates/switch-workstations.js";
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core.js';


class SwitchWorkstations extends CustomElement {

    // initializing plugin

    initialize() { 
        api.waitUntil('rosterContactsFetched').then( () => {
            this.model = _converse.workstation || []
            this.modeldata = _converse.workstationdata || []
            this.listenTo(this.model, 'add', () => this.requestUpdate());
            this.listenTo(this.model, 'destroy', () => this.requestUpdate());
            this.listenTo(this.model, 'remove', () => this.requestUpdate());
            this.listenTo(this.modeldata, 'change:selected_workstation', () =>  this.requestUpdate());
            this.requestUpdate();
            api.listen.on('showHubTooltip', (data) => {
                this.hoveredHubId = data?.hoveredHubId;
                this.isTextCopied = data?.isTextCopied;
                this.requestUpdate();
            });
            if(this.model?.models?.length){
                api.trigger('allWorkstationsData');
            }
        });

    }

    render () { // eslint-disable-line class-methods-use-this
        if (!this.model) return ``;
        return tpl_switch_workstations(this);
    }
}

api.elements.define('converse-switch-workstations', SwitchWorkstations);
