const { _converse } = require("@converse/headless/core");
const { THEMES } = require("../shared/constants");

define(function (require, exports, module) {
    const Helper = {
        escapeHtml (unsafe) {
            return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
        },
        async geoLocation () {
            return new Promise( async (resolve, reject) => {
                try{
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition( (data) => {
                            resolve({
                                status: true,
                                message: "Success, Found",
                                data
                            })
                        });
                      } else {
                        resolve({
                            status: false,
                            message: "Geolocation is not supported by this browser",
                            data: []
                        })
                      }
                    }catch(e) {
                    reject(e)
                }
            })
        },

        diffInSeconds (timestampA, timestampB) {
            //  absolute value added incase you just want the diff but don't care which came first
            return (Math.abs(timestampB - timestampA)) / 1000
        },

        YesterdayTime () {
            var ts = Math.round(new Date().getTime());
            var tsYesterday = ts - (24 * 3600 *1000);
            return tsYesterday;
        },
        TomorrowTime () {
            var ts = Math.round(new Date().getTime());
            var tsTomorrow = ts - (24 * 3600 *1000);
            return tsTomorrow;
        },
        timeDistance (date1, date2) {
            let distance = Math.abs(date1 - date2);
            const hours = Math.floor(distance / 3600000);
            distance -= hours * 3600000;
            const minutes = Math.floor(distance / 60000);
            distance -= minutes * 60000;
            const seconds = Math.floor(distance / 1000);
            return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
        },
        isThemeDracula(){
            let theme;
            try{
                const user = JSON.parse(localStorage.getItem('user'));
                theme = user?.theme || 'classic'
            }catch{
                theme = 'classic'
            }
            return theme === THEMES.DRACULA;
        },
        getCurrentTheme() {
            let theme;
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                theme = user?.theme || 'classic';
            } catch {
                theme = 'classic';
            }
            return theme;
        },
        getLocalUser(){
            return JSON.parse(localStorage.getItem('user'));
        },
        formatTime(milliseconds) {
            if(!milliseconds) return
            const date = new Date(milliseconds);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
        
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        
            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        },
        convertUtcToUserTime(utcMilliseconds) {
            const userTimeZoneOffset = new Date().getTimezoneOffset() * 60000; // Convert minutes to milliseconds;
            return new Date(utcMilliseconds + userTimeZoneOffset);
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        getTextWidth(inputText="") {
     
            font = "16px times new roman";
         
            canvas = document.createElement("canvas");
            context = canvas.getContext("2d");
            context.font = font;
            width = context.measureText(inputText).width;
            formattedWidth = (Math.ceil(width)*2) + "px";
         
            return formattedWidth;
        }
    
    };
    module.exports = Helper;
});