import { _converse } from '@converse/headless/core.js';
import { html } from 'lit';
import { __ } from 'i18n';
import '../../../styles/invite-members.scss';
import { sendInvitation } from '@converse/headless/utils/serverApis';

export default el => {
    const invite_member_to_workstation_email = el.model.get(`invite_member_to_workstation_email`) || ``;
    const member_emails = el.model.get(`member_emails`) || [];

    async function InviteMembers() {
        if(!member_emails.length){
            return
        }
        el.model.save({ btnText: 'Loading...' });
        const emails = member_emails.join(',');
        const company_id = _converse?.xmppstatus?.get('company_id');
        const response = await sendInvitation({ emails, company_id });
        const { message, success } = response;
        el.model.save({ apiResponse: { message, success }, member_emails: success ? []: member_emails, emailError: '', btnText: 'Invite' });
    }

    async function handleAddEmail() {
        
        const invite_member_to_workstation_email_ev = await el.model.get('invite_member_to_workstation_email_ev');

        const member_email = await el.model.get('invite_member_to_workstation_email');
        if (!member_email.length) {
            return;
        }

        if (!member_email?.match(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/)) {
            await el.model.save({ emailError: 'Invalid email' });
        } else {
            const member_emails = (await el.model.get(`member_emails`)) || [];
            if (!member_emails.includes(member_email)) {
                member_emails.push(member_email);
                const invite_member_to_workstation_email = ``;
                await el.model.save({ member_emails, invite_member_to_workstation_email, emailError: '' });
                invite_member_to_workstation_email_ev.target.value = '';
            } else {
                await el.model.save({ emailError: 'Email already added' });
            }
        }
    }

    async function removeEmail(emailToBeRemoved) {
        const filteredEmails = member_emails.filter(email => email !== emailToBeRemoved);
        await el.model.save({ member_emails: filteredEmails });
    }

    return html`<div class="invite-members-modal px-4">
        <div class="d-flex align-items-center">
            <div
                class="ml-2"
                style="font-size: 1rem;max-width: 90%; max-height: 50px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis;"
            ></div>
        </div>
        <div class="d-flex flex-column align-items-center text-white w-100">
            <div class="row justify-content-center text-white w-100 my-2">
                <div class="col-md-8 col-sm-12">
                    <div class="mb-4 text-center text-${el.model.get('apiResponse')?.success ? 'success' : 'danger'}">
                        ${el.model.get('apiResponse')?.message}
                    </div>
                    <label class="ms-4 font-size" htmlFor="email"> Invite Team Members </label>
                    <div class="d-flex input-wrapper bg-secondary-color mt-2">
                        <input
                            @keyup=${ev => {
                                el.model.save({ invite_member_to_workstation_email: ev.target.value, invite_member_to_workstation_email_ev: ev });
                            }}
                            type="text"
                            id="email"
                            class="text-white bg-secondary-color w-100 font-size"
                            placeholder="Email Address"
                            value=${invite_member_to_workstation_email || ""}
                            @keydown=${async e => {
                                if (e.key === 'Enter') {
                                    await handleAddEmail();
                                }
                            }}
                        />
                        <div
                            @click=${async () => {
                                await handleAddEmail();
                            }}
                            role="button"
                        >
                            <img src="/images/hookz-office/add.svg" alt="add" />
                        </div>
                    </div>
                    <div class="ml-3 my-2 text-danger">${el.model.get('emailError')}</div>
                    <div class="ms-4 mt-2 emails-to-be-invited">
                        ${member_emails.map(data => {
                            return html`<div
                                class="d-flex align-items-center justify-content-between px-3 text-white w-100"
                            >
                                <span class="font-size selected-email">${data}</span>
                                <img
                                    class="remove-email"
                                    src="/images/hookz-office/remove.svg"
                                    alt="remove"
                                    @click=${() => removeEmail(data)}
                                />
                            </div>`;
                        })}
                    </div>
                    <div class="row justify-content-center mt-3 mb-5 w-100">
                        <div class="col-md-6 col-sm-12">
                            <button
                                type="button"
                                class="btn btn-lg text-white invite-btn mt-3 w-100 font-size"
                                @click="${InviteMembers}"
                                ${!member_emails.length ? 'disabled' : ''}
                            >
                                ${el.model.get('btnText') || 'Invite'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column align-items-end mt-4">
            <button
                type="button"
                class="btn btn-light text-dark mt-2 font-size cancel-btn"
                data-dismiss="modal"
                style="width: 20%;"
            >
                ${__('Cancel')}
            </button>
        </div>
    </div>`;
};
