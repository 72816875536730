import tpl_profile from './templates/profile.js';
import tpl_profile_dracula from './templates/themes/dracula/profile.js';
import tpl_profile_wingtrill_dark from './templates/themes/wingtrill/dark/profile.js';
import tpl_profile_wingtrill_light from './templates/themes/wingtrill/light/profile.js';
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core';
import { getCurrentTheme } from '../../utils/helper.js';
import { THEMES } from '../../shared/constants.js';

class Profile extends CustomElement {
    initialize () {
        this.model = _converse.xmppstatus;
        window.addEventListener('storage', () => {
            this.requestUpdate()
          })
        this.listenTo(this.model, "change", () => this.requestUpdate());
        this.listenTo(this.model, "vcard:add", () => this.requestUpdate());
        this.listenTo(this.model, "vcard:change", () => this.requestUpdate());
    }

    render () {
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                template = tpl_profile_wingtrill_light(this);
                break;
            case WINGTRILL_DARK:
                template = tpl_profile_wingtrill_dark(this);
                break;

            case DRACULA:
                template = tpl_profile_dracula(this);
                break;

            default:
                template = tpl_profile(this);
        }
        return template;
    }

    showProfileModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-profile-modal', { model: this.model }, ev);
    }

    showStatusChangeModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-chat-status-modal', { model: this.model }, ev);
    }

    showUserSettingsModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-user-settings-modal', { model: this.model, _converse }, ev);
    }
}

api.elements.define('converse-user-profile', Profile);
