import tpl_newchatmenu from './templates/newchatmenu.js';
import tpl_newchatmenu_dracula from './templates//themes/dracula/newchatmenu.js';
import tpl_newchatmenu_wingtrill_light from './templates//themes/wingtrill/light/newchatmenu.js';
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core';
import { getCurrentTheme } from '../../utils/helper.js';
import { THEMES } from '../../shared/constants.js';

class NewChatMenu extends CustomElement {
    initialize () {
        this.model = _converse.xmppstatus;
        this.listenTo(this.model, "change", () => this.requestUpdate());
        api.listen.on('syncing_contact', (isSyncingContact) => {
            this.isSyncingContact = isSyncingContact;
            this.requestUpdate();
        });
    }

    render() {
        if(!this.model.get(`chatmenu`)) return;
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                template = tpl_newchatmenu_wingtrill_light(this);
                break;

            case WINGTRILL_DARK:
                template = tpl_newchatmenu(this);
                break;

            case DRACULA:
                template = tpl_newchatmenu_dracula(this);
                break;

            default:
                template = tpl_newchatmenu(this);
        }
        return template;
    }

    showProfileModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-profile-modal', { model: this.model }, ev);
    }

    showStatusChangeModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-chat-status-modal', { model: this.model }, ev);
    }

    showUserSettingsModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-user-settings-modal', { model: this.model, _converse }, ev);
    }
}

api.elements.define('converse-new-chat-menu', NewChatMenu);
