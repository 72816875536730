import RosterContact from './contact.js';
import log from "@converse/headless/log";
import { Collection } from "@converse/skeletor/src/collection";
import { Model } from "@converse/skeletor/src/model";
import { _converse, api, converse } from "@converse/headless/core";
import { initStorage } from '@converse/headless/utils/storage.js';
import { rejectPresenceSubscription } from './utils.js';
import { syncContactsOnAppOpenViaCS, userGroup, userPepNodes } from '../../utils/serverApis.js';
import { setData, getData } from '../../utils/localStore.js';
import { isUndefined, reject } from 'lodash-es';
import { BOT_JID, LOCAL_STORAGE, MUCLIGHT, XMPP_HOST } from '../../../shared/constants'
import { CHAT_TYPE } from '../muclight/constants.js';
import { isThemeDracula } from '../../../utils/helper.js';
const { Strophe, $iq, sizzle, u } = converse.env;


const RosterContacts = Collection.extend({
    model: RosterContact,

    initialize () {
        const id = `roster.state-${_converse.bare_jid}-${this.get('jid')}`;
        this.state = new Model({ id, 'collapsed_groups': [] });
        initStorage(this.state, id);
        this.state.fetch();
    },

    onConnected () {
        // Called as soon as the connection has been established
        // (either after initial login, or after reconnection).
        // Use the opportunity to register stanza handlers.
        this.registerRosterHandler();
        this.registerRosterXHandler();
    },

    registerRosterHandler () {
        // Register a handler for roster IQ "set" stanzas, which update
        // roster contacts.
        _converse.connection.addHandler(iq => {
            _converse.roster.onRosterPush(iq);
            return true;
        }, Strophe.NS.ROSTER, 'iq', "set");
    },

    registerRosterXHandler () {
        // Register a handler for RosterX message stanzas, which are
        // used to suggest roster contacts to a user.
        let t = 0;
        _converse.connection.addHandler(
            function (msg) {
                window.setTimeout(
                    function () {
                        _converse.connection.flush();
                        _converse.roster.subscribeToSuggestedItems.bind(_converse.roster)(msg);
                    }, t);
                t += msg.querySelectorAll('item').length*250;
                return true;
            },
            Strophe.NS.ROSTERX, 'message', null
        );
    },

    /**
     * Fetches the roster contacts, first by trying the browser cache,
     * and if that's empty, then by querying the XMPP server.
     * @returns {promise} Promise which resolves once the contacts have been fetched.
     */
    async fetchRosterContacts () {
        const result = await new Promise((resolve, reject) => {
            this.fetch({
                'add': true,
                'silent': true,
                'success': resolve,
                'error': (_, e) => reject(e)
            });
        });
        if (u.isErrorObject(result)) {
            log.error(result);
            // Force a full roster refresh
            _converse.session.save('roster_cached', false)
            this.data.save('version', undefined);
        }

        if (_converse.session.get('roster_cached')) {
            /**
             * The contacts roster has been retrieved from the local cache (`sessionStorage`).
             * @event _converse#cachedRoster
             * @type { _converse.RosterContacts }
             * @example _converse.api.listen.on('cachedRoster', (items) => { ... });
             * @example _converse.api.waitUntil('cachedRoster').then(items => { ... });
             */
            api.trigger('cachedRoster', result);
        } else {
            _converse.send_initial_presence = true;
            return _converse.roster.fetchFromServer();
        }
    },

    subscribeToSuggestedItems (msg) {
        Array.from(msg.querySelectorAll('item')).forEach(item => {
            if (item.getAttribute('action') === 'add') {
                _converse.roster.addAndSubscribe(
                    item.getAttribute('jid'),
                    _converse.xmppstatus.getNickname() || _converse.xmppstatus.getFullname()
                );
            }
        });
        return true;
    },

    isSelf (jid) {
        return u.isSameBareJID(jid, _converse.connection.jid);
    },

    /**
     * Add a roster contact and then once we have confirmation from
     * the XMPP server we subscribe to that contact's presence updates.
     * @method _converse.RosterContacts#addAndSubscribe
     * @param { String } jid - The Jabber ID of the user being added and subscribed to.
     * @param { String } name - The name of that user
     * @param { Array.String } groups - Any roster groups the user might belong to
     * @param { String } message - An optional message to explain the reason for the subscription request.
     * @param { Object } attributes - Any additional attributes to be stored on the user's model.
     */
    async addAndSubscribe (jid, name, groups, message, attributes) {
        const contact = await this.addContactToRoster(jid, name, groups, attributes);
        if (contact instanceof _converse.RosterContact) {
            contact.subscribe(message);
        }
    },

    /**
     * Send an IQ stanza to the XMPP server to add a new roster contact.
     * @method _converse.RosterContacts#sendContactAddIQ
     * @param { String } jid - The Jabber ID of the user being added
     * @param { String } name - The name of that user
     * @param { Array.String } groups - Any roster groups the user might belong to
     * @param { Function } callback - A function to call once the IQ is returned
     * @param { Function } errback - A function to call if an error occurred
     */
    sendContactAddIQ (jid, name, groups) {
        name = name ? name : null;
        const iq = $iq({'type': 'set'})
            .c('query', {'xmlns': Strophe.NS.ROSTER})
            .c('item', { jid, name });
        groups.forEach(g => iq.c('group').t(g).up());
        return api.sendIQ(iq);
    },

    /**
     * Adds a RosterContact instance to _converse.roster and
     * registers the contact on the XMPP server.
     * Returns a promise which is resolved once the XMPP server has responded.
     * @method _converse.RosterContacts#addContactToRoster
     * @param { String } jid - The Jabber ID of the user being added and subscribed to.
     * @param { String } name - The name of that user
     * @param { Array.String } groups - Any roster groups the user might belong to
     * @param { Object } attributes - Any additional attributes to be stored on the user's model.
     */
    async addContactToRoster (jid, name, groups, attributes) {
        await api.waitUntil('rosterContactsFetched');
        groups = groups || [];
        try {
            await this.sendContactAddIQ(jid, name, groups);
        } catch (e) {
            const { __ } = _converse;
            log.error(e);
            alert(__('Sorry, there was an error while trying to add %1$s as a contact.', name || jid));
            return e;
        }
        return this.create(Object.assign({
            'ask': undefined,
            'nickname': name,
            groups,
            jid,
            'requesting': false,
            'subscription': 'none'
        }, attributes), {'sort': false});
    },

    async subscribeBack (bare_jid, presence) {
        const contact = this.get(bare_jid);
        if (contact instanceof _converse.RosterContact) {
            contact.authorize().subscribe();
        } else {
            // Can happen when a subscription is retried or roster was deleted
            const nickname = sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, presence).pop()?.textContent || null;
            const contact = await this.addContactToRoster(bare_jid, nickname, [], {'subscription': 'from'});
            if (contact instanceof _converse.RosterContact) {
                contact.authorize().subscribe();
            }
        }
    },

    /**
     * Handle roster updates from the XMPP server.
     * See: https://xmpp.org/rfcs/rfc6121.html#roster-syntax-actions-push
     * @method _converse.RosterContacts#onRosterPush
     * @param { XMLElement } IQ - The IQ stanza received from the XMPP server.
     */
    onRosterPush (iq) {
        return new Promise(async (resolve, reject) => {
            const id = iq.getAttribute('id');
            const from = iq.getAttribute('from');
            if (from && from !== _converse.bare_jid) {
                // https://tools.ietf.org/html/rfc6121#page-15
                //
                // A receiving client MUST ignore the stanza unless it has no 'from'
                // attribute (i.e., implicitly from the bare JID of the user's
                // account) or it has a 'from' attribute whose value matches the
                // user's bare JID <user@domainpart>.
                log.warn(
                    `Ignoring roster illegitimate roster push message from ${iq.getAttribute('from')}`
                );
                return;
            }
            api.send($iq({type: 'result', id, from: _converse.connection.jid}));

            const query = sizzle(`query[xmlns="${Strophe.NS.ROSTER}"]`, iq).pop();
            this.data.save('version', query.getAttribute('ver'));

            let items = sizzle(`item`, query);
            if (items.length > 1) {
                log.error(iq);
                throw new Error('Roster push query may not contain more than one "item" element.');
            }
            if (items.length === 0) {
                log.warn(iq);
                log.warn('Received a roster push stanza without an "item" element.');
                return;
            }
                let iqpepNodeJids = {
                    ids : []
                }
                items = items.pop()
                const jid = items?.getAttribute('jid')
                if(!jid){
                    log.warn(iq);
                    log.warn(`unable to get jid ${jid}`);
                    resolve(false)
                    return;    
                }
                iqpepNodeJids.ids.push(jid)
                        
                let datacalled = await userPepNodes(iqpepNodeJids)
                for(const datacalledD of Object.entries(datacalled.result)){
                    for(const datacalledDcontent of datacalledD)
                    {
                        if(datacalledDcontent.items)
                        {
                            for(let dataz of datacalledDcontent.items)
                            {
                                let datazToStanza = dataz
                                if (typeof datazToStanza === 'string') {
                                    datazToStanza = u.toStanza(dataz);
                                }
                                if(sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, datazToStanza).pop())
                                {
                                    items.setAttribute(`name`,sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, datazToStanza).pop()?.textContent)
                                }
                                if(sizzle(`image_url[xmlns="${Strophe.NS.IMAGE_URL}"]`, datazToStanza).pop())
                                {
                                    items.setAttribute(`image`,sizzle(`image_url[xmlns="${Strophe.NS.IMAGE_URL}"]`, datazToStanza).pop()?.textContent)
                                }
                            }
                        }
                    }
                }
                this.updateContact(items);
            /**
             * When the roster receives a push event from server (i.e. new entry in your contacts roster).
             * @event _converse#rosterPush
             * @type { XMLElement }
             * @example _converse.api.listen.on('rosterPush', iq => { ... });
             */
            api.trigger('rosterPush', iq);
            resolve(true)
        })
        // return;
    },

    rosterVersioningSupported () {
        return api.disco.stream.getFeature('ver', 'urn:xmpp:features:rosterver') && this.data.get('version');
    },
    /**
     * Fetch User Jids Information in Chunk
     */
    async fetchJidsInfo(data){
        return new Promise(async (resolve,reject)=>{
            try{
                const userInboxPepNode = []
                let inboxpepNodeJids = {
                 ids : []
                }
                let inboxDataI = 0
                let inboxDataItemI = 1
                for(const inboxData of data){
                     if(!inboxData.includes(api.settings.get(`muclight_domain`))){
                         inboxpepNodeJids.ids.push(inboxData)
                         if(inboxDataI>=9 || data.length==inboxDataItemI) {
                             inboxDataI = 0
                             let datacalled = await userPepNodes(inboxpepNodeJids)
                             for(const datacalledD of Object.entries(datacalled.result)){
                                 for(const datacalledDcontent of datacalledD)
                                 {
                                     if(datacalledDcontent.items)
                                     {
                                         if(!userInboxPepNode[datacalledDcontent.p_key])
                                         {
                                             userInboxPepNode[datacalledDcontent.p_key] = {
                                                 device: {
                                                     id: '',
                                                     content: ''
                                                 },
                                                 nickname:datacalledDcontent.mobile,
                                                 image:'',
                                                 status:''
                                             }
                                         }
                                         for(let dataz of datacalledDcontent.items)
                                         {
                                             let datazToStanza = dataz
                                             if (typeof datazToStanza === 'string') {
                                                 datazToStanza = u.toStanza(dataz);
                                             }
                                             if(sizzle(`list[xmlns="${Strophe.NS.OMEMO}"]`, datazToStanza).pop())
                                             {
                                                 userInboxPepNode[datacalledDcontent.p_key].device.id = sizzle(`device`, datazToStanza).pop()?.getAttribute('id');
                                                 userInboxPepNode[datacalledDcontent.p_key].device.content = dataz;
                                             }
                                             if(sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, datazToStanza).pop())
                                             {
                                                 userInboxPepNode[datacalledDcontent.p_key].nickname = sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, datazToStanza).pop()?.textContent;
                                             }
                                             if(sizzle(`image_url[xmlns="${Strophe.NS.IMAGE_URL}"]`, datazToStanza).pop())
                                             {
                                                 userInboxPepNode[datacalledDcontent.p_key].image = sizzle(`image_url[xmlns="${Strophe.NS.IMAGE_URL}"]`, datazToStanza).pop()?.textContent;
                                             }
                                             if(sizzle(`status[xmlns="${Strophe.NS.PUBSUB_STATUS}"]`, datazToStanza).pop())
                                             {
                                                 userInboxPepNode[datacalledDcontent.p_key].status = sizzle(`status[xmlns="${Strophe.NS.PUBSUB_STATUS}"]`, datazToStanza).pop()?.textContent
                                             }                                            
                                         }
                                     }
     
                                 }
                             }
                             inboxpepNodeJids.ids = []    
                         }
                         inboxDataI++
                     }
                     inboxDataItemI++         
                    }
                resolve(userInboxPepNode)
            }catch(error){
                reject(error)
            }
        })
    },
    async switchWorkstationRosters() {
        for(const rostersD of _converse.roster.models) {
            rostersD.save({hidden: true})
        }
        let workstationEmployees = []
        if(_converse.workstation.get(_converse.workstationdata.get('selected_workstation'))) {
            for (const jidsWorkstation of Object.entries(_converse.workstation.get(_converse.workstationdata.get('selected_workstation')).get('EmployeeCompanies'))) {
                jidsWorkstation[1].username ? _converse?.roster?.get(jidsWorkstation[1].username+`@`+Strophe.getDomainFromJid(_converse.bare_jid))?.save({hidden: false}) : ``
            }
        }
        return true;
    },
    /**
     * Fetch the roster from the XMPP server
     * @emits _converse#roster
     * @returns {promise}
     */
    async fetchFromServer () {
        const stanza = $iq({
            'type': 'get',
            'id': u.getUniqueId('roster')
        }).c('query', {xmlns: Strophe.NS.ROSTER});
        if (this.rosterVersioningSupported()) {
            stanza.attrs({'ver': this.data.get('version')});
        }
        let storageData = await getData(LOCAL_STORAGE.KEY.USER)
        if(!storageData)
        {
            return
        }
        const logged_user_Data = await this.fetchJidsInfo([_converse.bare_jid])
        storageData.profile.nickName = logged_user_Data[_converse.bare_jid]?.nickname
        storageData.profile.status = logged_user_Data[_converse.bare_jid]?.status
        storageData.profile.imageUrl = logged_user_Data[_converse.bare_jid]?.image
        
        await setData(LOCAL_STORAGE.KEY.USER,storageData)
        const iq = await api.sendIQ(stanza, null, false);

        if (iq.getAttribute('type') === 'result') {
            const query = sizzle(`query[xmlns="${Strophe.NS.ROSTER}"]`, iq).pop();
            if (query) {
                const items = sizzle(`item`, query);
                if (!this.data.get('version') && this.models.length) {
                    // We're getting the full roster, so remove all cached
                    // contacts that aren't included in it.
                    const jids = items.map(item => item.getAttribute('jid'));
                    this.forEach(m => !m.get('requesting') && !jids.includes(m.get('jid')) && m.destroy());
                }
                const contactsData = {};
                const user = JSON.parse(localStorage.getItem('user'));
                let syncContactFromServer = await syncContactsOnAppOpenViaCS({ username: user?.userId });
                const contacts = syncContactFromServer?.data;
                if(Array.isArray(contacts)){
                    localStorage.setItem('contacts', JSON.stringify(contacts));
                    contacts.forEach(contact => {
                        let friend_id = contact.friend_id
                        // let full_name = contacts.full_name
                        // let mobile = contacts.mobile
                        // let pic = contacts.pic
                        contactsData[friend_id] = {
                            ...contact
                        }
                    })
                }
                // let dataall = await Promise.all(syncContactFromServer.data.map(async contacts => {
                //     let friend_id = contacts.friend_id
                //     // let full_name = contacts.full_name
                //     // let mobile = contacts.mobile
                //     // let pic = contacts.pic
                //     contactsData[friend_id] = {
                //         ...contacts
                //     }
                // }))
                // .then(async (results) => {
                //     await setData('contact',{INDEX:syncContactFromServer.data, JID:contactsData})
                //     }).catch((e) => {
                //     // Handle errors here
                // });
                /* fetch group */
                const muclight_domain = api.settings.get('muclight_domain')
                let groupData = await userGroup()
                const groupDetail = {}
                for(const groupDatas of groupData.result){
                    groupDetail[`${groupDatas.groupId}@${muclight_domain}`] = groupDatas
                }
                /* fetch group */
                /* inbox calling */
               let getInbox = await u.fetchInbox()
               let inboxpepNodeJids = {
                ids : []
            }
               let inboxDataI = 0
               let inboxDataItemI = 1
               for (const item of items){
                if(!getInbox.inboxData.includes(item.getAttribute('jid'))){
                    getInbox.inboxData.push(item.getAttribute('jid'))
                }
               }
            //    
            let ChatJids = await getData(`chat-jids`)
            ChatJids = ChatJids ? ChatJids : []
            for (const ChatJidsData of ChatJids) {
                if(!getInbox.inboxData.includes(ChatJidsData)){
                    getInbox.inboxData.push(ChatJidsData)
                }
            }

               const userInboxPepNode = await this.fetchJidsInfo(getInbox.inboxData)
                for(const inboxData of getInbox.inboxData){
                    if(userInboxPepNode[inboxData]){
                        let jid = `${inboxData}`
                        let item = document.createElement(`item`)
                        item.setAttribute(`subscription`,'both')
                        item.setAttribute(`jid`,jid)
                        item.setAttribute(`name`,userInboxPepNode[inboxData]?.nickname || jid)
                        item.setAttribute(`image`,userInboxPepNode[inboxData]?.image || _converse.DEFAULT_IMAGE)
                        item.setAttribute('time',getInbox.inboxDataTimeStamp[jid] || new Date().getTime())
                        this.updateContact(item)
                    }else if(groupDetail[inboxData]){
                        const type = CHAT_TYPE.GROUP_CHAT
                        let jid = `${inboxData}`
                        let item = document.createElement(`item`)
                        item.setAttribute(`subscription`,'both')
                        item.setAttribute(`jid`,jid)
                        item.setAttribute(`name`,groupDetail[inboxData].roomname)
                        item.setAttribute(`image`,groupDetail[inboxData].image_url)
                        item.setAttribute(`type`,type)
                        item.setAttribute('time',getInbox.inboxDataTimeStamp[jid])
                        this.updateContact(item)
                    }
                }
                /* inbox calling */

                let groupDatasI = 0
                for(const groupDatas of groupData.result){
                    const jid = `${groupDatas.groupId}@${muclight_domain}`;
                    if(!getInbox.inboxData.includes(jid)){
                    const subscription = "both";

                    const ask = "";
                    const image = groupDatas.image_url;
                    const nickname = groupDatas.roomname
                    const groups = [];
                    const type = CHAT_TYPE.GROUP_CHAT
                    let item = document.createElement(`item`)
                    item.setAttribute(`subscription`,'both')
                    item.setAttribute(`jid`,jid)
                    item.setAttribute(`image`,image)
                    item.setAttribute(`name`,nickname)
                    item.setAttribute(`type`,type)
                    item.setAttribute(`ask`,ask)
                    item.setAttribute(`time`,new Date(`1995`).getTime())
                    this.updateContact(item,groupDatas)
                    groupDatasI++
                    }
                }


                for(const contact of Object.keys(contactsData))
                {
                    let jid = `${contact}@${XMPP_HOST}`
                    if(!getInbox.inboxData.includes(jid)){
                        let item = document.createElement(`item`)
                        item.setAttribute(`subscription`,'both')
                        item.setAttribute(`jid`,jid)
                        item.setAttribute(`name`,contactsData[contact].full_name)
                        item.setAttribute(`time`,new Date(`1995`).getTime())
                        this.updateContact(item)
                    }
                }
                    let item = document.createElement(`item`)
                    item.setAttribute(`subscription`,'both')
                    item.setAttribute(`jid`,_converse.bare_jid)
                    item.setAttribute(`hidden`,true)
                    item.setAttribute(`name`,_converse.xmppstatus.vcard.get(`nickname`))
                    item.setAttribute(`time`,new Date(`1995`).getTime())
                    this.updateContact(item)

                    item = document.createElement(`item`)
                    item.setAttribute(`subscription`,'both')
                    item.setAttribute(`jid`, BOT_JID)
                    item.setAttribute(`hidden`,false)
                    item.setAttribute(`name`,'Wingtrill AI')
                    item.setAttribute(`time`,new Date(`1995`).getTime())
                    this.updateContact(item)

                this.data.save('version', query.getAttribute('ver'));                
            }
        } else if (!u.isServiceUnavailableError(iq)) {
            // Some unknown error happened, so we will try to fetch again if the page reloads.
            log.error(iq);
            log.error("Error while trying to fetch roster from the server");
            return;
        }

        _converse.session.save('roster_cached', true);
        /**
         * When the roster has been received from the XMPP server.
         * See also the `cachedRoster` event further up, which gets called instead of
         * `roster` if its already in `sessionStorage`.
         * @event _converse#roster
         * @type { XMLElement }
         * @example _converse.api.listen.on('roster', iq => { ... });
         * @example _converse.api.waitUntil('roster').then(iq => { ... });
         */
        api.trigger('roster', iq);
        await this.get(_converse.bare_jid)?.openChat(true,true)
        this.get(_converse.bare_jid)?.destroy()
        if(Object.entries(_converse.chatboxviews.views)[1]){
            await this.get(Object.entries(_converse.chatboxviews.views)[1][0])?.openChat(true)
        }
    },

    /**
     * Update or create RosterContact models based on the given `item` XML
     * node received in the resulting IQ stanza from the server.
     * @param { XMLElement } item
     */

    updateContact (item,info=[]) {
        const jid = item.getAttribute('jid');
        const jid_domain = Strophe.getDomainFromJid(jid)
        const contact = this.get(jid);
        const subscription = item.getAttribute("subscription");
        if (subscription === "remove") {
            return contact?.destroy();
        }
        const stamp = (item.getAttribute(`time`)!='undefined' && item.getAttribute(`time`)!='Invalid Date') ? item.getAttribute(`time`) : new Date('1995').getTime()
        const type = jid_domain===MUCLIGHT.DOMAIN ? CHAT_TYPE.GROUP_CHAT : CHAT_TYPE.CHAT;
        let hidden = item.getAttribute("hidden") ? true : false;
        let workstationEmployees = []
        if(_converse.workstation.get(_converse.workstationdata.get('selected_workstation'))) {
            for (const jidsWorkstation of Object.entries(_converse.workstation.get(_converse.workstationdata.get('selected_workstation')).get('EmployeeCompanies'))) {
                workstationEmployees[jidsWorkstation[1].username] = jidsWorkstation[1]
            }
            hidden = workstationEmployees[Strophe.getNodeFromJid(jid)] ? false : true
        }
        if(type===CHAT_TYPE.GROUP_CHAT && isThemeDracula()) {
            hidden = !jid.includes(`workstation_id_${_converse.workstationdata?.get('selected_workstation')}_workstation_id_`)
        }
        const ask = item.getAttribute("ask");
        const image = item.getAttribute('image');
        const nickname = item.getAttribute('name');
        const groups = [...new Set(sizzle('group', item).map(e => e.textContent))];
        if (contact) {
            // We only find out about requesting contacts via the
            // presence handler, so if we receive a contact
            // here, we know they aren't requesting anymore.
            // contact.save({ subscription, type, image, info, ask, nickname, groups, 'requesting': null });
            contact.save({ subscription, chat_type: type, hidden, image, ask, nickname, groups, 'requesting': null });
        } else {
            // this.create({ nickname, type, image, info, ask, groups, jid, subscription }, {sort: false});
            this.create({ nickname, stamp, chat_type: type, hidden, info:info, image, ask, groups, jid, subscription }, {sort: false});
        }
        api.trigger(`refresh_contact_list`)
    },

    createRequestingContact (presence) {
        const bare_jid = Strophe.getBareJidFromJid(presence.getAttribute('from'));
        const nickname = sizzle(`nick[xmlns="${Strophe.NS.NICK}"]`, presence).pop()?.textContent || null;
        const user_data = {
            'jid': bare_jid,
            'subscription': 'none',
            'ask': null,
            'requesting': true,
            'nickname': nickname
        };
        /**
         * Triggered when someone has requested to subscribe to your presence (i.e. to be your contact).
         * @event _converse#contactRequest
         * @type { _converse.RosterContact }
         * @example _converse.api.listen.on('contactRequest', contact => { ... });
         */
        api.trigger('contactRequest', this.create(user_data));
    },

    handleIncomingSubscription (presence) {
        const jid = presence.getAttribute('from'),
            bare_jid = Strophe.getBareJidFromJid(jid),
            contact = this.get(bare_jid);

        if (!api.settings.get('allow_contact_requests')) {
            const { __ } = _converse;
            rejectPresenceSubscription(
                jid,
                __("This client does not allow presence subscriptions")
            );
        }
        if (api.settings.get('auto_subscribe')) {
            if ((!contact) || (contact.get('subscription') !== 'to')) {
                this.subscribeBack(bare_jid, presence);
            } else {
                contact.authorize();
            }
        } else {
            if (contact) {
                if (contact.get('subscription') !== 'none')  {
                    contact.authorize();
                } else if (contact.get('ask') === "subscribe") {
                    contact.authorize();
                }
            } else {
                this.createRequestingContact(presence);
            }
        }
    },

    handleOwnPresence (presence) {
        const jid = presence.getAttribute('from'),
              resource = Strophe.getResourceFromJid(jid),
              presence_type = presence.getAttribute('type');

        if ((_converse.connection.jid !== jid) &&
                (presence_type !== 'unavailable') &&
                (api.settings.get('synchronize_availability') === true ||
                 api.settings.get('synchronize_availability') === resource)) {
            // Another resource has changed its status and
            // synchronize_availability option set to update,
            // we'll update ours as well.
            const show = presence.querySelector('show')?.textContent || 'online';
            _converse.xmppstatus.save({'status': show}, {'silent': true});

            const status_message = presence.querySelector('status')?.textContent;
            if (status_message) {
                _converse.xmppstatus.save({'status_message': status_message});
            }
        }
        if (_converse.jid === jid && presence_type === 'unavailable') {
            // XXX: We've received an "unavailable" presence from our
            // own resource. Apparently this happens due to a
            // Prosody bug, whereby we send an IQ stanza to remove
            // a roster contact, and Prosody then sends
            // "unavailable" globally, instead of directed to the
            // particular user that's removed.
            //
            // Here is the bug report: https://prosody.im/issues/1121
            //
            // I'm not sure whether this might legitimately happen
            // in other cases.
            //
            // As a workaround for now we simply send our presence again,
            // otherwise we're treated as offline.
            api.user.presence.send();
        }
    },

    presenceHandler (presence) {
        const presence_type = presence.getAttribute('type');
        if (presence_type === 'error') return true;

        const jid = presence.getAttribute('from');
        const bare_jid = Strophe.getBareJidFromJid(jid);
        if (this.isSelf(bare_jid)) {
            return this.handleOwnPresence(presence);
        } else if (sizzle(`query[xmlns="${Strophe.NS.MUC}"]`, presence).length) {
            return; // Ignore MUC
        }

        const status_message = presence.querySelector('status')?.textContent;
        const contact = this.get(bare_jid);

        if (contact && (status_message !== contact.get('status'))) {
            contact.save({'status': status_message});
        }

        if (presence_type === 'subscribed' && contact) {
            contact.ackSubscribe();
        } else if (presence_type === 'unsubscribed' && contact) {
            contact.ackUnsubscribe();
        } else if (presence_type === 'unsubscribe') {
            return;
        } else if (presence_type === 'subscribe') {
            this.handleIncomingSubscription(presence);
        } else if (presence_type === 'unavailable' && contact) {
            const resource = Strophe.getResourceFromJid(jid);
            contact.presence.removeResource(resource);
        } else if (contact) {
            // presence_type is undefined
            contact.presence.addResource(presence);
        }
    }
});

export default RosterContacts;
