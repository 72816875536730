import './moments-header-span-listing.js'
import { CustomElement } from 'shared/components/element.js';
import tpl_user_moments from './templates/user-moments.js';
import tpl_user_moments_dracula from './templates/themes/dracula/user-moments.js';
import { _converse, api, converse } from '@converse/headless/core';
import { deleteStory } from '@converse/headless/utils/serverApis.js';
import { isThemeDracula } from '../../utils/helper.js';
const { u } = converse.env
export default class UserMoments extends CustomElement {
    static get properties() {
        return {
            data: { type: Object },
            width: { type: String },
            height: { type: String },
            nonce: { type: String }, // Used to trigger rerenders
        };
    }

    constructor() {
        super();
        this.width = 36;
        this.height = 36;
    }

    initialize() {
        api.waitUntil('rosterContactsFetched').then( () => {
            this.model = _converse.storydata || []
            this.story = _converse.story || []

            this.listenTo(this.model, 'add', () => this.requestUpdate());
            this.listenTo(this.model, 'destroy', () => this.requestUpdate());
            this.listenTo(this.model, 'remove', () => this.requestUpdate());
            this.listenTo(this.model, 'change:story_id', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:view_list', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:jid', () =>  this.requestUpdate());    
            
            this.listenTo(this.story, 'add', () => this.requestUpdate());
            this.listenTo(this.story, 'destroy', () => this.requestUpdate());
            this.listenTo(this.story, 'remove', () => this.requestUpdate());
            this.listenTo(this.story, 'change:story_id', () =>  this.requestUpdate());    
            this.listenTo(this.story, 'change:jid', () =>  this.requestUpdate());    
            this.requestUpdate()
        });
    }

    render() {
        u.hideAllChatBox()
        if(this?.story?.length>0 && this?.model?.get('jid') && this?.model?.get('story_id')) {
            this.customInterval()
            return isThemeDracula() ? tpl_user_moments_dracula(this) : tpl_user_moments(this);
        }else{
            return ``;
        }
    }

    async removeStory (Obj) {
        try {
            const confirm = await api.confirm(`Do you want to delete this moment?`);
            if(!confirm) {
                return false
            }
            const get_story_data = this.story.get(_converse.bare_jid)
            if(!get_story_data) {
                this.updateData({jid:``, story_id:``, view_list: false})
                await api.confirm(`Story not found.`)
                return true
            }
            let storyData = get_story_data.get('stories').filter(data=> data.story_id===Obj.attrs.story_id)
            if(storyData.length===0) {
                this.updateData({jid:``, story_id:``, view_list: false})
                await api.confirm(`Story not found.`)
                return true
            }
            storyData = storyData[storyData.length-1]
            const storyToSplice = get_story_data.get(`stories`)
            const storyIndexToSplice = storyToSplice.indexOf(storyData)
            // Terminate StoryView Window Before Removing from Database Begins
            const currentSelectedStoryJid = this.model.get('jid')
            const currentSelectedStoryId = this.model.get('story_id')
            if(storyData.story_id===currentSelectedStoryId && storyData.contact_jid===currentSelectedStoryJid) {
                await this.updateData({jid:``, story_id:``, view_list: false})
            }
            // Terminate StoryView Window Before Removing from Database Ends
            const restStories = storyToSplice.splice(storyIndexToSplice,1)
            storyToSplice.length<1 ? get_story_data.destroy() : ``
            const body = {
                storyId: Obj.attrs.story_id,
                payload: JSON.stringify({
                    storyId: Obj.attrs.story_id,
                    type: `delete`
                })
            }
            const response = await deleteStory(body);
            return true;
        }catch(error) {
            console.log(`error`,error)
            await api.confirm(`Unable to Remove Story.`)
        }
        return false;
    }

    async updateData (attrs) {
        this.model ? await this.model.save({jid: '', story_id: ``, view_list: false, story_span_width: 0, story_span_width_interval: ``}) : ``
        this.model ? await this.model.save({...attrs,view_list: false, story_span_width: 0, story_span_width_interval: ``}) : ``
    }
    previous() {
        if(!this.model.get('jid') || !this.model.get('story_id')) {
            return ``
        }
        if(this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])-1>-1) {
            const jid = this.story.get(this.model.get('jid')).get('stories')[this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])-1].contact_jid
            const story_id = this.story.get(this.model.get('jid')).get('stories')[this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])-1].story_id
            this.updateData({jid,story_id,view_list: false})
        }else{
            if(this.story.indexOf(this.story.get(this.model.get('jid')))-1>-1) {
                const jid = this.story.models[this.story.indexOf(this.story.get(this.model.get('jid')))-1].get('contact_jid')
                const story_id = this.story.models[this.story.indexOf(this.story.get(this.model.get('jid')))-1].get('stories')[0].story_id
                this.updateData({jid,story_id,view_list: false})
            }
        }
    }
    next () {
        if(!this.model.get('jid') || !this.model.get('story_id')) {
            return ``
        }
        if(this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])>-1 && this.story.get(this.model.get('jid')).get('stories')[this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])+1]) {
                const jid = this.story.get(this.model.get('jid')).get('stories')[this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])+1].contact_jid
                const story_id = this.story.get(this.model.get('jid')).get('stories')[this.story.get(this.model.get('jid')).get('stories').indexOf(this.story.get(this.model.get('jid')).get('stories').filter(data => data.story_id===this.model.get('story_id'))[0])+1].story_id
                this.updateData({jid,story_id,view_list: false})
            }else{
            if(this.story.indexOf(this.story.get(this.model.get('jid')))+1>-1 && this.story.models[this.story.indexOf(this.story.get(this.model.get('jid')))+1]) {
                const jid = this.story.models[this.story.indexOf(this.story.get(this.model.get('jid')))+1].get('contact_jid')
                const story_id = this.story.models[this.story.indexOf(this.story.get(this.model.get('jid')))+1].get('stories')[0].story_id
                this.updateData({jid,story_id,view_list: false})
            }else{
                this.updateData({jid:``,story_id:``,view_list: false})
            }
        }
    }

    customInterval() {
        if(this.model.get('jid') && this.model.get('story_id')) {
            clearInterval(_converse.usermoment_customInterval)
            _converse.usermoment_customInterval = setTimeout(() => {
                this.next()
            }, 1000*30);    
        }
    }
}

api.elements.define('converse-user-moments', UserMoments);
