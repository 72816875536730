import DropdownBase from "shared/components/dropdown.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import { html } from "lit";
import { initStorage } from '@converse/headless/utils/storage.js';
import { until } from 'lit/directives/until.js';
import { isThemeDracula } from '../../utils/helper';

const u = converse.env.utils;


export default class ReactionEmojiDropdown extends DropdownBase {

    static get properties() {
        return {
            chatview: { type: Object },
            message: { type: Object },
            messagemodel: { type: Object },
        };
    }

    constructor () {
        super();
        // This is an optimization, we lazily render the emoji picker, otherwise tests slow to a crawl.
        this.render_emojis = false;
    }

    initModel () {
        if (!this.init_promise) {
            this.init_promise = (async () => {
                await api.emojis.initialize()
                const id = `converse.emoji-${_converse.bare_jid}-${this.chatview.model.get('jid')}`;
                this.model = new _converse.EmojiPicker({'id': id});
                initStorage(this.model, id);
                await new Promise(resolve => this.model.fetch({'success': resolve, 'error': resolve}));
                // We never want still be in the autocompleting state upon page load
                this.model.set({'autocompleting': null, 'ac_position': null});
                this.default_emoji_icons_menu = true
                api.listen.on(`default_emoji_icons_menu`, (data) => {
                    this.default_emoji_icons_menu = data.default_emoji_icons_menu
                    this.requestUpdate()
                })

                this.messagemodel.listenTo(this.messagemodel, 'change:show_emoji_icon_force',  () => {
                    this.default_emoji_icons_menu = true
                    this.requestUpdate()
                });

            })();
        }
        return this.init_promise;
    }

    render() {
        if(!this.chatview) return ``;
        const is_groupchat = this.chatview?.model?.get('type') === _converse.CHATROOMS_TYPE;
        const color = is_groupchat ? '--muc-toolbar-btn-color' : '--chat-head-color';
        const isMeMessage = this.message.sender === 'me';
        if(isThemeDracula()){
            return html`
                <div class="dropup">
                    <div class="d-flex" style="background-color: #1F1F1F; border-radius: 30px;">
                    ${isMeMessage
                        ? html` <button
                              class="toggle-emojis py-1 px-2"
                              title="${__('Insert emojis')}"
                              @click=${async ev => {
                                  await this.messagemodel.save({ show_emoji_icon_force: false });
                                  api.trigger(`default_emoji_icons_menu`, { ev, default_emoji_icons_menu: true });
                              }}
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                          >
                            <img src="/images/hookz-office/chat-bubble/emoji.svg" alt="emojis" />                       
                          </button>`
                        : html` <div class="py-1 px-2" role="button" @click=${() => console.log('more-options')}>
                              <img src="/images/hookz-office/chat-header/arrow.svg" alt="more-options" />
                          </div>`}
                        <img src="/images/hookz-office/chat-header/vertical-line.svg" alt="-" height="20" style="width: 1px; height: 25px">
                        <div class="py-1 px-2" role="button" @click=${ async () => {
                            await this.chatview.model.save({reply_msg_id: this.message.msgid})
                        }}>
                            <img src="/images/hookz-office/message-reply.svg" alt="reply" />
                        </div>
                        <img src="/images/hookz-office/chat-header/vertical-line.svg" alt="-" height="20" style="width: 1px; height: 25px">
                        <div class="py-1 px-2" role="button" @click=${async (ev) => {
                            api.trigger(`converse-forward-message-modal`,{model: this.model, ev})
                        }}>
                            <img src="/images/hookz-office/message-forward.svg" alt="forward" />
                        </div>
                        <img src="/images/hookz-office/chat-header/vertical-line.svg" alt="-" height="20" style="width: 1px; height: 25px">
                        ${isMeMessage
                            ? html` <div class="py-1 px-2" role="button" @click=${() => console.log('more-options')}>
                                  <img src="/images/hookz-office/chat-header/arrow.svg" alt="more-options" />
                              </div>`
                            : html`<button
                                  class="toggle-emojis py-1 px-2"
                                  title="${__('Insert emojis')}"
                                  @click=${async ev => {
                                      await this.messagemodel.save({ show_emoji_icon_force: false });
                                      api.trigger(`default_emoji_icons_menu`, { ev, default_emoji_icons_menu: true });
                                  }}
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                              >
                                <img src="/images/hookz-office/chat-bubble/emoji.svg" alt="emojis" />                       
                              </button>`}
                    </div>
                    <div timer=${this.message.show_emoji_icon_force_timer ? this.message.show_emoji_icon_force_timer : Date.now()} style="${(this.message.show_emoji_icon_force) ? `display: block;` : `` }" class="dropdown-menu ${(this.message.show_emoji_icon_force) ? `show_emoji_icon_force default_emoji_icons_menu show` : this.default_emoji_icons_menu ? `default_emoji_icons_menu` : `dropdown-menu-width-500px-important`}" id="${this.message.msgid}-reaction-emoji-picker">
                        ${until(this.initModel().then(() => html`
                            <converse-reaction-emoji-picker
                                    .chatview=${this.chatview}
                                    .model=${this.model}
                                    .message=${this.message}
                                    @emojiSelected=${() => this.hideMenu()}
                                    .thisdata=${this}
                                    .default_emoji_icons_menu=${this.default_emoji_icons_menu}
                                    ?render_emojis=${this.render_emojis}
                                    current_category="${this.model.get('current_category') || ''}"
                                    current_skintone="${this.model.get('current_skintone') || ''}"
                                    query="${this.model.get('query') || ''}"
                            ></converse-reaction-emoji-picker>`), '')}
                    </div>
                </div>`;    
        }
        return html`
            <div class="dropup">
                <button class="emoji-icon-for-chat-msg toggle-emojis"
                        title="${__('Insert emojis')}"
                        @click=${async(ev) => {
                            await this.messagemodel.save({show_emoji_icon_force: false})
                            api.trigger(`default_emoji_icons_menu`,{ev,default_emoji_icons_menu:true})
                        }}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <converse-icon
                        color="var(${color})"
                        class="fa fa-smile "
                        path-prefix="${api.settings.get('assets_path')}"
                        size="1.3em"></converse-icon>
                </button>
                <div timer=${this.message.show_emoji_icon_force_timer ? this.message.show_emoji_icon_force_timer : Date.now()} style="${(this.message.show_emoji_icon_force) ? `display: block;` : `` }" class="dropdown-menu ${(this.message.show_emoji_icon_force) ? `show_emoji_icon_force default_emoji_icons_menu show` : this.default_emoji_icons_menu ? `default_emoji_icons_menu` : `dropdown-menu-width-500px-important`}" id="${this.message.msgid}-reaction-emoji-picker">
                    ${until(this.initModel().then(() => html`
                        <converse-reaction-emoji-picker
                                .chatview=${this.chatview}
                                .model=${this.model}
                                .message=${this.message}
                                @emojiSelected=${() => this.hideMenu()}
                                .thisdata=${this}
                                .default_emoji_icons_menu=${this.default_emoji_icons_menu}
                                ?render_emojis=${this.render_emojis}
                                current_category="${this.model.get('current_category') || ''}"
                                current_skintone="${this.model.get('current_skintone') || ''}"
                                query="${this.model.get('query') || ''}"
                        ></converse-reaction-emoji-picker>`), '')}
                </div>
            </div>`;
    }

    connectedCallback () {
        super.connectedCallback();
        this.render_emojis = false;
    }

    toggleMenu (ev) {
        ev.stopPropagation();
        ev.preventDefault();
        const dropdown_menu = document.querySelectorAll('.dropdown-menu.show');
        Object.keys(dropdown_menu).map(index => {
                u.removeClass('show', dropdown_menu[index]);
        })
        if (u.hasClass('show', this.menu)) {
            if (u.ancestor(ev.target, '.toggle-emojis')) {
                this.hideMenu();
            }
        } else {
            this.showMenu();
        }
    }

    async showMenu () {
        this.hideMenu();
        await this.initModel();
        if (!this.render_emojis) {
            // Trigger an update so that emojis are rendered
            this.render_emojis = true;
            this.requestUpdate();
            await this.updateComplete;
        }
        super.showMenu();
        setTimeout(() => this.querySelector('.emoji-search')?.focus());
    }
}

api.elements.define('converse-reaction-emoji-dropdown', ReactionEmojiDropdown);
