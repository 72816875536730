import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';
import tpl_contacts_list from 'plugins/muclight-views/modals/templates/contact-list.js' 
import tpl_contacts_list_dracula from 'plugins/muclight-views/modals/templates/themes/dracula/contact-list.js' 

export default (el) => {
    return html`${tpl_contacts_list_dracula({model: _converse.roompanel, modal: el.modal}, CHAT_TYPE.GROUP_CHAT, true)}`;
}
