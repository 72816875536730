import { _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { __ } from 'i18n';
import { CALL_TYPE } from '@converse/headless/plugins/muclight/constants';

export default (el) => {
    el.receivedCall()
    return html`
    <div class="incomming-call-main-container">
        <div class="incomming-call-sub-container">
            <div class="incomming-call-encryption-text py-4">
               <span>HookZOffice ${ el.CALL_TYPE_VALUE } Call</span>
               <img src="/images/hookz-office/close-modal.svg" alt="remove" style="width: 30px;" />
            </div>
            <div class="incomming-recipient-avatar">
                <converse-avatar class="avatar" .data=${el.model.attributes} height="150" width="150"></converse-avatar>
            </div>
            <div class="incomming-recipient-name">
                ${ el.model.get('nickname') }
            </div>
            <div class="incomming-call-action-buttons-container">
                <div class="incomming-call-reject-button"
                @click=${ async (ev) => {
                    await el.chatbox.sendMessage(el.decline)
                    el.stopCall()
                }}
                >
                    <img src="../../../../images/end-call.svg" alt="x" />
                    <p>Decline</p>
                </div>
                <div class="incomming-call-accept-button"
                @click=${ async (ev) => {
                    el.answer.message.signalName = el.answer.signalName
                    el.answer.sdpdata.signalName = el.answer.signalName
                    await el.chatbox.sendMessage(el.answer)
                }}
                >
                    <img src="../../../../images/accept-call.svg" alt="*" />
                    <p>Accept</p>
                </div>
            </div>
        </div>
    </div>
    `;
}