import isNaN from 'lodash-es/isNaN';
import isObject from 'lodash-es/isObject';
import { Model } from '@converse/skeletor/src/model.js';
import { _converse, api, converse } from '@converse/headless/core';
import { THEMES } from '../../../shared/constants';
import { getLocalUser } from '../../../utils/helper';
import { companyView } from '../../utils/serverApis';

const { Strophe, $pres } = converse.env;

const XMPPStatus = Model.extend({
    defaults() {
        let theme;
        let company_id;
        try {
            const user = getLocalUser()
            theme = user.theme || THEMES.DEFAULT;
            company_id = user?.company_id
        } catch {
            theme = THEMES.DEFAULT;
        }
        return {
            'status': api.settings.get('default_state'),
            theme,
            company_id,
            call: [],
            stream: {}
        };
    },

    initialize () {
        this.on('change', item => {
            if (!isObject(item.changed)) {
                return;
            }
            if ('status' in item.changed || 'status_message' in item.changed) {
                api.user.presence.send(this.get('status'), null, this.get('status_message'));
            }
        });
    },

    getNickname () {
        return api.settings.get('nickname');
    },

    getFullname () {
        // Gets overridden in converse-vcard
        return '';
    },

    async updateCompanyId(company_id) {
        await _converse.xmppstatus.save({company_id})
        let localdata = JSON.parse(localStorage.getItem('user'))
        const obj = Object.entries(localdata)
        if(!obj.length) {
            return true
        }
        localdata.company_id = company_id
        localStorage.setItem('user',JSON.stringify(localdata))
        return true
    },

    async companyDetails(company_id=_converse.xmppstatus.get('company_id')) {
        let companydata = await companyView(company_id)
        if(companydata.success) {
            await _converse.xmppstatus.save({companydata: companydata?.data?.rows[0] ? companydata?.data?.rows[0] : {}})

        }
    },

    async constructPresence (type, to=null, status_message) {
        type = typeof type === 'string' ? type : (this.get('status') || api.settings.get("default_state"));
        status_message = typeof status_message === 'string' ? status_message : this.get('status_message');
        let presence;
        const attrs = {to};
        if ((type === 'unavailable') ||
                (type === 'probe') ||
                (type === 'error') ||
                (type === 'unsubscribe') ||
                (type === 'unsubscribed') ||
                (type === 'subscribe') ||
                (type === 'subscribed')) {
            attrs['type'] = type;
            presence = $pres(attrs);
        } else if (type === 'offline') {
            attrs['type'] = 'unavailable';
            presence = $pres(attrs);
        } else if (type === 'online') {
            presence = $pres(attrs);
        } else {
            presence = $pres(attrs).c('show').t(type).up();
        }

        if (status_message) {
            presence.c('status').t(status_message).up();
        }

        const priority = api.settings.get("priority");
        presence.c('priority').t(isNaN(Number(priority)) ? 0 : priority).up();
        if (_converse.idle) {
            const idle_since = new Date();
            idle_since.setSeconds(idle_since.getSeconds() - _converse.idle_seconds);
            presence.c('idle', {xmlns: Strophe.NS.IDLE, since: idle_since.toISOString()});
        }
        presence = await api.hook('constructedPresence', null, presence);
        return presence;
    }
});

export default XMPPStatus;
