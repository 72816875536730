import WorkStation from './workstation.js';
import log from "@converse/headless/log";
import { Collection } from "@converse/skeletor/src/collection";
import { Model } from "@converse/skeletor/src/model";
import { _converse, api, converse } from "@converse/headless/core";
import { initStorage } from '@converse/headless/utils/storage.js';
import { rejectPresenceSubscription } from './utils.js';
import { myCompanies, syncContactsOnAppOpenViaCS, userGroup, userPepNodes } from '../../utils/serverApis.js';
import { setData, getData } from '../../utils/localStore.js';
import { isUndefined, reject } from 'lodash-es';
import { LOCAL_STORAGE } from '../../../shared/constants'
import { CHAT_TYPE } from '../muclight/constants.js';
import { getLocalUser } from '../../../utils/helper.js';
const { Strophe, $iq, sizzle, u } = converse.env;


const WorkStations = Collection.extend({
    model: WorkStation,

    initialize () {
        const id = `workstation.data-${_converse.bare_jid}}`;
        const getLocal = getLocalUser()
        this.data = _converse.workstationdata = new Model({selected_workstation: getLocal.company_id || 0});
        this.data.id = id;
        initStorage(this.data, id);
        this.data.fetch();
    },

    /**
     * Fetch the workstation from the XMPP server
     * @emits _converse#workstation
     * @returns {promise}
     */
    async fetchFromServer () {
        // const companies = await myCompanies()
        // if(companies.success) {
        //     if(companies.data.count) {
        //         for (const data of companies.data.rows) {
        //             const list = this.get(data.id);
        //             if (list) {
        //                 // We only find out about requesting contacts via the
        //                 // presence handler, so if we receive a contact
        //                 // here, we know they aren't requesting anymore.
        //                 list.save({ ...data });
        //             } else {
        //                 this.create({ ...data }, {sort: false});
        //             }
        //         }
        //     }
        // }

                    // let item = document.createElement(`item`)
                    // item.setAttribute(`subscription`,'both')
                    // item.setAttribute(`jid`,_converse.bare_jid)
                    // item.setAttribute(`name`,_converse.xmppstatus.vcard.get(`nickname`))
                    // item.setAttribute(`time`,new Date(`1995`).getTime())
                    // this.updateContact(item)
    },

});

export default WorkStations;
