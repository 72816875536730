import { __ } from 'i18n';
import { _converse } from '@converse/headless/core';
import { getStandaloneButtons, getDropdownButtons } from 'shared/chat/utils.js';
import { html } from "lit";
import { until } from 'lit/directives/until.js';
import { STATUSES } from '../../../../rosterview/constants';
import { CALL_TYPE, CALL_TYPE_CONST, CALL_VERSION, CHAT_TYPE, MESSAGE_TYPE, SIGNAL_NAME } from '@converse/headless/plugins/muclight/constants';


export default (o) => {
    const isOneToOneChat = o.chat_type === CHAT_TYPE.CHAT;
    const image_attributes = o.model?.attributes?.image?.length ? o.model.attributes : o.model.vcard?.attributes
    const i18n_profile = __("The User's Profile Image");
    const avatar = html `<span title="${i18n_profile}">
                            <converse-avatar
                                class="avatar chat-msg__avatar"
                                .data=${image_attributes}
                                nonce=${o.model.vcard?.get('vcard_updated')}
                                height="50" width="50">
                            </converse-avatar>
                        </span>`;
    const display_name = o.model.get(`info`)?.roomname || o.model.get(`nickname`) || o.model.getDisplayName();
    const show = o?.presence?.attributes?.show || o.model?.presence?.attributes?.show || 'offline';
    let classes, color;
    if (show === 'online') {
        [classes, color] = ['fa fa-circle', 'chat-status-online'];
    } else if (show === 'dnd') {
        [classes, color] = ['fa fa-minus-circle', 'chat-status-busy'];
    } else if (show === 'away') {
        [classes, color] = ['fa fa-circle', 'chat-status-away'];
    } else {
        [classes, color] = ['fa fa-circle', 'subdued-color'];
    }
    const desc_status = STATUSES[show];
    const video_call_json = {
        appVersion: CALL_VERSION,
        call_type: CALL_TYPE_CONST.VIDEO,
        message: {
            sdp: [],
            callType: CALL_TYPE.VIDEO,
            jid: o.jid,
            signalName: SIGNAL_NAME.START_CALL
        },
        message_type: MESSAGE_TYPE.WEBRTC,
        signalName: SIGNAL_NAME.START_CALL,
        type: CHAT_TYPE.START_CALL
    }
    const audio_call_json = {
        appVersion: CALL_VERSION,
        call_type: CALL_TYPE_CONST.AUDIO,
        message: {
            sdp: [],
            callType: CALL_TYPE.AUDIO,
            jid: o.jid,
            signalName: SIGNAL_NAME.START_CALL
        },
        message_type: MESSAGE_TYPE.WEBRTC,
        signalName: SIGNAL_NAME.START_CALL,
        type: CHAT_TYPE.START_CALL
    }

    return html`
        <div class="chatbox-title ${o.status ? '' : 'chatbox-title--no-desc'}">
            <div class="chatbox-title--row d-flex justify-content-between">
                <div class="chatbox-title__text" title="${o.jid}">
                    ${!_converse.api.settings.get('singleton')
                        ? html`<converse-controlbox-navback jid="${o.jid}"></converse-controlbox-navback>`
                        : ''}
                    ${o.type !== _converse.HEADLINES_TYPE
                        ? html`<a class="show-msg-author-modal contactInfoSlider">${avatar}</a>`
                        : ''}
                    <div style="display:flex;flex-direction:column;">
                        ${o.type !== _converse.HEADLINES_TYPE
                            ? html`<a class="user show-msg-author-modal contactInfoSlider">${display_name}</a>`
                            : display_name}
                            ${isOneToOneChat ? html`<div class="chatbox__user-status"><p>${show}</p></div>`: html``}
                    </div>
                </div>

                <div class="chatbox_title-icons d-flex justify-content-between" style="width: 17rem; margin-right: 3px;">
                    <div class="d-flex justify-content-evenly align-items-center" style="background-color: #2B2B2B; border-radius: 30px; width: 160px; font-size: 14px;" role="button">
                        <img src="/images/hookz-office/roster-contact/pin-chat.svg" alt="pinned messages" style="width: 10px;" />
                        <span>Pinned messages</span>
                    </div>
                    <div class="d-flex justify-content-evenly align-items-center" style="background-color: #2B2B2B; border-radius: 30px; width: 100px;">
                        <img @click=${ (ev) => {
                            o.model.sendMessage(audio_call_json)
                        }} src="/images/hookz-office/voice-call.svg" alt="voice-call" style="width: 20px; cursor: pointer;" />
                        <img src="/images/hookz-office/chat-header/vertical-line.svg" alt="-" height="20" style="width: 1px; height: 25px" />
                        <img @click=${ (ev) => {
                            o.model.sendMessage(video_call_json)
                        }} src="/images/hookz-office/video-call.svg" alt="video-call" style="width: 20px; cursor: pointer;" />
                    </div>
                </div>
            </div>
            ${/*<span class="chatbox_title-seperator"></span>*/ ''}
            <div class="chatbox-title__buttons row no-gutters">
                ${until(getDropdownButtons(o.heading_buttons_promise, true), '')}
                ${until(getStandaloneButtons(o.heading_buttons_promise), '')}
            </div>
        </div>
        ${o.status ? html`<p class="chat-head__desc">${o.status}</p>` : ''}
    `;
}
