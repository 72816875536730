import { _converse } from '@converse/headless/core.js';
import { html } from 'lit';
import { __ } from 'i18n';
import { CALL_TYPE } from '@converse/headless/plugins/muclight/constants';

export default el => {
    const call_receipents = Object.entries(_converse.xmppstatus.get('call'));
    call_receipents.map(data => {
        el.CALL_TYPE_VALUE === CALL_TYPE.VIDEO ? data[1]?.video?.play() : ``;
    });
    _converse.xmppstatus.get('call')[el.model.get('jid')]?.mvideo?.play();
    return html`
        <header>
            <div class="d-flex justify-content-between px-5 py-4 bg-secondary-color">
                <span class="d-flex align-items-center">
                    <span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                        <img class="bluetooth-icon" src="/images/hookz-office/call/hamburger.svg" alt="record" />
                    </span>
                    <span class="ms-3">${el.model.get('nickname')}</span>
                </span>
                <span class="d-flex align-items-center">
                    <span class="me-3">Add Participants</span
                    ><span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                        <img class="bluetooth-icon" src="/images/hookz-office/call/add-participants.svg" alt="record" />
                    </span>
                </span>
            </div>
        </header>
        ${call_receipents.map( data => {
            return html`
            <div class="call-user-container">
            <div class="call-recipient-call-container">
            ${ el.CALL_TYPE_VALUE === CALL_TYPE.VIDEO ? html `${data[1].video}` : html`
            <div class="call-recipient-avatar">
                <converse-avatar class="avatar" .data=${_converse.roster.get(data[0]).attributes} height="150" width="150"></converse-avatar>
            </div>
            `}
                <div class="call-recipient-name">
                    ${ _converse.roster.get(data[0]).get('nickname') }
                </div>
            </div>
        </div>
            `
        })}
        <footer>
            <div class="d-flex justify-content-between px-5 py-4 bg-secondary-color">
                <div class="d-flex">
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/speaker.svg" alt="record" />
                        </span>
                        <span>Speaker</span>
                    </span>
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/bluetooth.svg" alt="record" />
                        </span>
                        <span>Bluetooth</span>
                    </span>
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/record.svg" alt="record" />
                        </span>
                        <span>Record</span>
                    </span>
                </div>
                <div class="d-flex">
                    <span class="mute-unmute-video-call-color common-icon-wrapper mid-icons-wrapper mr-3">
                        <img src="/images/hookz-office/call/unmute.svg" alt="record" />
                    </span>
                    <span class="mute-unmute-video-call-color common-icon-wrapper mid-icons-wrapper">
                        <img class="video-call-icon" src="/images/hookz-office/call/video.svg" alt="record" />
                    </span>
                    <span
                        class="end-call-color common-icon-wrapper mid-icons-wrapper ml-3"
                        @click=${async ev => {
                            el.leaveCall();
                        }}
                    >
                        <img class="end-call-icon" src="/images/hookz-office/call/end-call.svg" alt="record" />
                    </span>
                </div>
                <div class="d-flex">
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper d-flex flex-column">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/share-screen.svg" alt="record" />
                        </span>
                        <span>Share</span>
                    </span>
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper d-flex">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/participants.svg" alt="record" />
                        </span>
                        <span>Participants</span>
                    </span>
                    <span class="d-flex flex-column align-items-center">
                        <span class="btn-color common-icon-wrapper icon-wrapper">
                            <img class="bluetooth-icon" src="/images/hookz-office/call/chat.svg" alt="record" />
                        </span>
                        <span>Chat</span>
                    </span>
                </div>
            </div>
        </footer>
    `;
};
