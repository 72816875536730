import tpl_alert_component from './modal-alert.js';
import { html } from 'lit';
import { __ } from 'i18n';
import { modal_close_button, close_modal_header_btn_dracula } from 'plugins/modal/templates/buttons.js';
import { isThemeDracula } from '../../../utils/helper.js';

export default el => {
    const isOutgoingOrConnectedCall = el.is_modal_type === 'call_connected' || el.is_modal_type === 'outgoing_call';
    const isIncomingCall = el.is_modal_type === 'incomming_call';
    const alert = el.model?.get('alert');
    const level = el.model?.get('level') ?? '';
    const currentGroupScreen = el.model.get('group_screen');
    let previousGroupScreen = 0;
    if (currentGroupScreen > 0) {
        previousGroupScreen = currentGroupScreen - 1;
    }
    return html`<div
                  class="modal-dialog ${el.is_full_screen_modal ? `modal-fullscreen` : ``}"
                  role="document"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  data-backdrop="static"
                  data-keyboard="false"
              >
                  <div class="modal-content ${isThemeDracula() ? 'dracula' : ''} ${
        el.is_modal_type === 'incomming_call' ? 'modal-incomming-call' : ''
    }  ${
        el.is_modal_type === 'outgoing_call' ? 'modal-outgoing-call' : ''
    } ${
        el.is_modal_type === 'call_connected' ? 'modal-connected-call' : ''
    }" style="${
        el.is_full_screen_modal
            ? `modal-fullscreen`
            : el.is_modal_type === 'incomming_call'
            ? `width: 20vw; max-height: 92vh`
            : el.is_modal_type === 'outgoing_call' ? "": el.is_modal_type === 'call_connected' ? "": `width: 38vw; max-height: 92vh;`
    } overflow-y: auto; ${isThemeDracula() ? 'background: #1F1F1F !Important; border-color: #818181;' : ''} ${isOutgoingOrConnectedCall ? 'border-radius: 0px !important; border: none;': isIncomingCall ? "border-radius: 30px": ''}">
                    ${el.showModalHeader ? html`<div class="${isOutgoingOrConnectedCall ? '': 'modal-header mt-3'} ${level} d-flex align-items-center">
                    <h5 class="modal-title ${isThemeDracula() ? 'font-color-dracula' : ''}">
                    ${
                        isThemeDracula() && currentGroupScreen > 0
                            ? html`<img
                                  src="/images/hookz-office/back-arrow.svg"
                                  class="mr-2 cursor-pointer"
                                  alt="back"
                                  @click=${() => el.model.save({ group_screen: previousGroupScreen })}
                              />`
                            : ''
                    }
                    ${el.getModalTitle()}
                    </h5>
                    ${isThemeDracula() && !el.disable_close_button_modal ? html`<span @click=${() => el.model.save({group_screen:0, alert: {              
                    }})}>${close_modal_header_btn_dracula}</span>` : ''}
                </div>`: html``}
                    <div class="modal-body p-0">
                        <span class="modal-alert">
                            ${
                                alert
                                    ? tpl_alert_component({ 'type': `alert-${alert.type}`, 'message': alert.message })
                                    : ''
                            }
                        </span>
                        ${el.renderModal?.() ?? ''}
                    </div>
                    ${el.renderModalFooter?.() ?? html`<div class="modal-footer">${modal_close_button}</div>`}
                    </div>
              </div>
          </form>`;
};
