import { html } from "lit";
const getImgHref = (image, image_type) => {
    return (image.startsWith('data:') || image.startsWith('https://') || image.split('/default-avatars').length>1 || image.split('/default-group-icon').length>1) ? image : `data:${image_type};base64,${image}`;
}

export default  (o) => {
    if (o.image) {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" class="avatar ${o.classes}" width=${o.width} height=${o.height} >
                <image style="border-radius:50%;" width=${o.width} height=${o.height} preserveAspectRatio="xMidYMid meet" href="${getImgHref(o.image, o.image_type)}"/>
            </svg>
            `;
    } else {
        return '';
    }
}
