import tpl_chat_header from "../templates/chat-header.js";
import tpl_chat_header_dracula from "../templates/themes/dracula/chat-header-dracula.js";
import tpl_chat_header_wingtrill_light from "../templates/themes/wingtrill/light/chat-header.js";
import tpl_chat_header_wingtrill_dark from "../templates/themes/wingtrill/dark/chat-header.js";
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core.js';
import { getCurrentTheme } from "../../../utils/helper.js";
import { THEMES } from "../../../shared/constants.js";

class ChatHeader extends CustomElement {

    async initialize () {
        this.model = _converse.xmppstatus
        this.listenTo(this.model, 'change', () => {
            this.requestUpdate()
        });
        this.listenTo(this.model, 'add', () => {
            this.requestUpdate()
        });
        api.listen.on(`updateTab`, (tab) => {
            this.activeTab = tab;
            this.requestUpdate()
        });
    }

    render () { // eslint-disable-line class-methods-use-this
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                template = tpl_chat_header_wingtrill_light(this);
                break;

            case WINGTRILL_DARK:
                template = tpl_chat_header_wingtrill_dark(this);
                break;

            case DRACULA:
                template = tpl_chat_header_dracula(this);
                break;

            default:
                template = tpl_chat_header(this);
        }
        return template;
    }
}

api.elements.define('converse-chat-header', ChatHeader);
