import { html } from "lit";
const getImgHref = (image, image_type) => {
    return (image.startsWith('data:') || image.startsWith('https://') || image.split('/default-avatars').length>1 || image.split('/default-group-icon').length>1) ? image : `data:${image_type};base64,${image}`;
}

function getNameInitials(name){
    if(!name) return;
    const namesArray = name.split(' ');
    if(namesArray.length > 1){
        return namesArray[0][0].toUpperCase() + namesArray[1][0].toUpperCase();
    }
    return namesArray[0][0].toUpperCase();
}

function getRandomHexColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
  
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  
    return color;
}

function renderImage(o) {
    if (o.isDefaultImage) {
        return html`<span
            class="avatar-initials-wrapper"
            style="border-radius:50%; width: ${o.width}px; height: ${o.height}px; background-color: ${getRandomHexColor()};"
            >${getNameInitials(o.name)}</span
        >`;
    }
    return html`<image
        style="border-radius:50%;"
        width=${o.width}
        height=${o.height}
        preserveAspectRatio="xMidYMid meet"
        href="${getImgHref(o.image, o.image_type)}"
    />`;
}

export default o => {
    if (!o.isDefaultImage && !o.image) return html``;

    if (o.isDefaultImage)
        return html` <span
            class="avatar-initials-wrapper"
            style="border-radius:50%; background-color: ${getRandomHexColor()};"
            >${getNameInitials(o.name)}</span
        >`;

    return html`
        <svg xmlns="http://www.w3.org/2000/svg" class="avatar ${o.classes}" width=${o.width} height=${o.height}>
            <image
                style="border-radius:50%;"
                width=${o.width}
                height=${o.height}
                preserveAspectRatio="xMidYMid meet"
                href="${getImgHref(o.image, o.image_type)}"
            />
        </svg>
    `;
};


