import 'shared/avatar/avatar.js';
import 'shared/chat/unfurl.js';
import { __ } from 'i18n';
import { html } from 'lit';
import { shouldRenderMediaFromURL } from '@converse/headless/utils/url.js';
import { _converse, api, converse } from '@converse/headless/core';
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants';

const { u } = converse.env;

export default (el, o) => {
    const is_displayed = el.model.get('is_displayed');
    const is_received = el.model.get('is_received');

    const showEmojiContainer = (e, el) => {
        el.model?.save({ hide_emoji_icon: false });
    };

    const hideEmojiContainer = (e, el) => {
        el.model.get('show_emoji_icon_force')
            ? el.model?.save({ hide_emoji_icon: false })
            : el.model?.save({ hide_emoji_icon: true });
    };

    o.pretty_time = new Date('1970-01-01T' + o.pretty_time + 'Z').toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: true,
        hour: '2-digit',
        minute: 'numeric',
    });

    if (o.is_deleted) {
        return ``;
    }

    const tpl_seen_checkmark = () => {
        // return html`<i style="color: rgb(30 209 86);" class="fa-solid fa-check-double"></i>`;
        return html`<svg xmlns="http://www.w3.org/2000/svg" width="12.84" height="9.236" viewBox="0 0 12.84 9.236">
            <g id="Group_16985" data-name="Group 16985" transform="translate(0.707 0.707)">
                <path
                    id="Path_14267"
                    data-name="Path 14267"
                    d="M-20675.234-6614.174l2.369,2.368,6.584-6.586"
                    transform="translate(20675.234 6618.392)"
                    fill="none"
                    stroke="#007fff"
                    stroke-linecap="round"
                    stroke-width="1"
                />
                <path
                    id="Path_14268"
                    data-name="Path 14268"
                    d="M-20673.318-6611.806h0l6.584-6.586"
                    transform="translate(20678.16 6619.628)"
                    fill="none"
                    stroke="#007fff"
                    stroke-linecap="round"
                    stroke-width="1"
                />
            </g>
        </svg> `;
    };

    const tpl_received_checkmark = () => {
        // return html`<i style="color: #a8aba1;" class="fa-solid fa-check-double"></i>`;
        return html`<svg xmlns="http://www.w3.org/2000/svg" width="12.84" height="9.236" viewBox="0 0 12.84 9.236">
            <g id="Group_16966" data-name="Group 16966" transform="translate(0.707 0.707)">
                <path
                    id="Path_14267"
                    data-name="Path 14267"
                    d="M-20675.234-6614.174l2.369,2.368,6.584-6.586"
                    transform="translate(20675.234 6618.392)"
                    fill="none"
                    stroke="#939aa5"
                    stroke-linecap="round"
                    stroke-width="1"
                />
                <path
                    id="Path_14268"
                    data-name="Path 14268"
                    d="M-20673.318-6611.806h0l6.584-6.586"
                    transform="translate(20678.16 6619.628)"
                    fill="none"
                    stroke="#939aa5"
                    stroke-linecap="round"
                    stroke-width="1"
                />
            </g>
        </svg> `;
    };

    const tpl_sent_checkmark = () => {
        // return html`<i style="color: #a8aba1;" class="fa-solid fa-check"></i>`;
        return html`<svg xmlns="http://www.w3.org/2000/svg" width="10.367" height="8" viewBox="0 0 10.367 8">
            <g id="Group_16986" data-name="Group 16986" transform="translate(0.707 0.707)">
                <path
                    id="Path_14267"
                    data-name="Path 14267"
                    d="M-20675.234-6614.174l2.369,2.368,6.584-6.586"
                    transform="translate(20675.234 6618.392)"
                    fill="none"
                    stroke="#939aa5"
                    stroke-linecap="round"
                    stroke-width="1"
                />
            </g>
        </svg> `;
    };

    const momentViewFromChatBox = (jid, id) => {
        const stories = _converse.stories[jid];
        let current_index = null;
        let stories_i = 0;
        for (const storiesData of stories) {
            if (storiesData?.story_id === id) {
                current_index = stories_i;
            }
            stories_i++;
        }
        if (current_index !== null) {
            api.trigger('show-user-moments', {
                moments: stories,
                currentMomentIdx: current_index,
                showRightArrow: stories.length === 1 ? false : stories.length - 1 === current_index ? false : true,
                showLeftArrow: false,
            });
        }
    };

    const i18n_new_messages = __('New messages');
    const is_followup = el.model.isFollowup();
    const image_attributes = el.model.contact?.attributes?.image?.length
        ? el.model.contact.attributes
        : el.model.vcard?.attributes;
    let images = o.media_urls ?? [];
    let firstImge = images[0];
    const storyData = el.model.get(`story_data`);
    let moment_reply = '';
    const thumbnailImgId = generateRandomString(5);
    if (storyData) {
        const storyData_body = JSON.parse(storyData.body);
        let imageSrc;
        const momentType = storyData_body.storiesData[0].mime_type.split('/')[0];
        const mediaUrl = storyData_body.storiesData[0].url;
        if (momentType === 'image') {
            imageSrc = mediaUrl;
        } else if (momentType === 'video') {
            var src = mediaUrl;
            var video = document.createElement('video');

            video.src = src;

            video.width = 40;
            video.height = 40;

            var canvas = document.createElement('canvas');
            canvas.width = 40;
            canvas.height = 40;
            var context = canvas.getContext('2d');

            video.addEventListener('loadeddata', function () {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const dataURI = canvas.toDataURL('image/jpeg');
                const momentImg = document.getElementById(thumbnailImgId);
                momentImg.src = dataURI;
            });
        }

        moment_reply = html` <div
            class="moment-reply-main-container px-2 ${o.sender === 'me'
                ? `background-them-dracula text-white`
                : `background-white text-black`}"
            @click=${async ev => {
                u.hideAllChatBox();
                await _converse.storydata.save({ jid: ``, story_id: ``, view_list: false });
                await _converse.storydata.save({
                    jid: storyData.contact_jid,
                    story_id: storyData.story_id,
                    view_list: false,
                });
            }}
        >
            <div class="moment-reply-sub-container d-flex">
                <div class="moment-reply-text-container text-left">
                    Moment<br /><i class="fas fa-camera-retro"></i> ${storyData_body.storiesData[0].mime_type
                        .split('/')[0]
                        .toUpperCase()}
                </div>
                <div class="moment-reply-image-container text-right text-white">
                    <img id=${thumbnailImgId} height="40px" width="40px" src="${imageSrc}" />
                </div>
            </div>
        </div>`;
    }

    function generateRandomString(length) {
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return randomString;
    }

    const reply_msg_data = el.model.get(`reply_msg_data`);
    const reply_msg_id = el.model.get(`reply_msg_id`);
    let msg_reply = '';
    if (reply_msg_data) {
        let reply_msg_data_text = reply_msg_data?.plaintext ? reply_msg_data?.plaintext : reply_msg_data?.body;
        let icon = ``;
        if (reply_msg_data.message_type == converse.MESSAGE_TYPE.LOCATION) {
            icon = html`<i class="fas fa-map-marker-alt"></i>`;
            reply_msg_data_text = `LOCATION`;
        } else if (reply_msg_data.message_type == converse.MESSAGE_TYPE.IMAGE) {
            icon = html`<i class="fas fa-camera-retro"></i>`;
            reply_msg_data_text = `IMAGE`;
        } else if (reply_msg_data.message_type == converse.MESSAGE_TYPE.VIDEO) {
            icon = html`<i class="fas fa-video-camera"></i>`;
            reply_msg_data_text = `VIDEO`;
        } else if (reply_msg_data.message_type == converse.MESSAGE_TYPE.AUDIO) {
            icon = html`<i class="fas fa-play"></i>`;
            reply_msg_data_text = `AUDIO`;
        } else if (reply_msg_data.message_type == converse.MESSAGE_TYPE.DOCUMENT) {
            icon = html`<i class="fas fa-file"></i>`;
            reply_msg_data_text = `DOCUMENT`;
        }
        let nickName = 'Unknown';
        if (reply_msg_data.from === _converse.bare_jid) {
            nickName = 'You';
        } else {
            if (_converse.xmppstatus.vcard.get(`nickname`)) {
                nickName = _converse.xmppstatus.vcard.get(`nickname`);
            } else if (reply_msg_data.nickname) {
                nickName = reply_msg_data.nickname;
            } else if (reply_msg_data.nick) {
                nickName = reply_msg_data.nick;
            }
        }
        msg_reply = html`<a href="#${reply_msg_id}">
            <div
                class="msg-reply-main-container p-2 ${o.sender === 'me'
                    ? `background-them-dracula text-white`
                    : `background-white text-black`}"
            >
                <div class="msg-reply-sub-container">
                    <div class="msg-reply-text-container text-left font-weight-bold">${nickName}</div>
                    <div class="msg-reply-image-container text-break">${icon} ${reply_msg_data_text}</div>
                </div>
            </div>
        </a>`;
    }

    let is_forwarded = ``;
    if (o.is_forward) {
        is_forwarded = html` <div
            class="p-1 msg-forward-main-container ${o.sender === 'me'
                ? `background-them-dracula text-white`
                : `background-white text-black`}"
        >
            <div class="msg-reply-sub-container">
                <div class="msg-forward-text-container text-left">
                    <i class="fa fa-angle-double-right"></i> Forwarded
                </div>
            </div>
        </div>`;
    }

    const isMyMessage = o.sender === 'me';

    return o.type === CHAT_TYPE.GROUP_CHAT
        ? html` ${o.is_first_unread
                  ? html` <div class="message separator d-none">
                        <hr class="separator" />
                        <span class="separator-text">${i18n_new_messages}</span>
                    </div>`
                  : ''}
              <div
                  class="d-flex"
                  @mouseenter=${ev => showEmojiContainer(ev, el)}
                  @mouseleave=${ev => hideEmojiContainer(ev, el)}
              >
                  <div class="w-100 chat-box" id="${o.msgid}">
                      <div
                          class="${o.sender === 'me' ? 'sent' : 'received left-in'} ${el.getExtraMessageClasses()}"
                          data-isodate="${o.time}"
                          data-msgid="${o.msgid}"
                          data-from="${o.from}"
                          data-encrypted="${o.is_encrypted}"
                      >
                          <div
                              class="message chat-msg d-inline-block ${o.sender === 'me' ? 'py-1 px-2' : ''}"
                              style="border-radius: 30px !Important"
                          >
                              <!-- Anchor to allow us to scroll the message into view -->
                              <a id="${o.msgid}"></a>
                              ${o.should_show_avatar && !is_followup && o.sender !== 'me'
                                  ? html` <div class="d-flex mt-2">
                                        <a
                                            class="d-none show-msg-author-modal ${o.sender === 'me'
                                                ? 'message-by-me'
                                                : 'contactInfoSlider'}"
                                        >
                                            <converse-avatar
                                                class="avatar align-self-center"
                                                .data=${image_attributes}
                                                nonce=${el.model.vcard?.get('vcard_updated')}
                                                height="40"
                                                width="40"
                                            >
                                            </converse-avatar>
                                        </a>
                                        <span class="chat-msg__author align-self-center"
                                            >${o?.nick?.length > 20 ? o?.nick.substring(0, 25) + '...' : o?.nick}</span
                                        >
                                    </div>`
                                  : ''}
                              <div
                                  class="d-flex flex-column width-100-precent chat-msg__content chat-msg__content--${o.sender} ${o.is_me_message
                                      ? 'chat-msg__content--action'
                                      : ''} ${o.sender !== 'me'
                                      ? 'mt-2 background-them-dracula p-1'
                                      : 'background-me-dracula'}"
                                  style="border-radius: 30px !important;"
                              >
                                  ${msg_reply} ${is_forwarded} ${moment_reply}
                                  <div
                                      class="p-1 chat-msg__body chat-msg__body--${o.message_type} ${o.received
                                          ? 'chat-msg__body--received'
                                          : ''} ${o.is_delayed ? 'chat-msg__body--delayed' : ''}"
                                  >
                                      <div class="chat-msg__message">
                                          ${o.is_me_message
                                              ? html` <time timestamp="${o.edited || o.time}" class="chat-msg__time"
                                                        >${o.pretty_time}</time
                                                    >&nbsp;
                                                    <span class="chat-msg__author"
                                                        >${o.is_me_message ? '**' : ''}${o.username}</span
                                                    >&nbsp;`
                                              : ''}
                                          ${o.is_retracted ? el.renderRetraction() : el.renderMessageText()}
                                      </div>
                                      <div class="d-flex flex-column justify-content-between ml-1">
                                          <converse-message-actions
                                              .model=${el.model}
                                              ?is_retracted=${o.is_retracted}
                                          ></converse-message-actions>
                                          <time
                                              style="width: 60px; font-size: 11px;"
                                              timestamp="${el.model.get('edited') || el.model.get('time')}"
                                              class="chat-msg__time text-right"
                                              >${o.pretty_time}</time
                                          >
                                      </div>
                                  </div>
                                  ${!o.is_me_message && !is_followup
                                      ? html` <span class="chat-msg__heading">
                                            <div>
                                                ${o.hats.map(
                                                    h => html`<span class="badge badge-secondary">${h.title}</span>`
                                                )}
                                            </div>
                                            <div class="d-flex">
                                                ${o.is_encrypted
                                                    ? html`<converse-icon
                                                          class="fa fa-lock align-self-center d-none"
                                                          size="1.1em"
                                                      ></converse-icon>`
                                                    : ''}
                                            </div>
                                        </span>`
                                      : ''}
                                  ${el.model.get('ogp_metadata')?.map(m => {
                                      if (el.model.get('hide_url_previews') === true) {
                                          return '';
                                      }
                                      if (!shouldRenderMediaFromURL(m['og:image'], 'image')) {
                                          return '';
                                      }
                                      return html` <converse-message-unfurl
                                          @animationend="${el.onUnfurlAnimationEnd}"
                                          class="${el.model.get('url_preview_transition')}"
                                          jid="${el.chatbox?.get('jid')}"
                                          description="${m['og:description'] || ''}"
                                          title="${m['og:title'] || ''}"
                                          image="${m['og:image'] || ''}"
                                          url="${m['og:url'] || ''}"
                                      >
                                      </converse-message-unfurl>`;
                                  })}
                                  <converse-message-reactionview .el=${el} ?is_retracted=${o.is_retracted}>
                                  </converse-message-reactionview>
                              </div>
                          </div>
                          ${o.sender === 'them'
                              ? html`<converse-reaction-emoji-dropdown
                        class="chat-reaction-emoji-container-${o.sender} ${
                                    o.show_emoji_icon_force ? `` : o.hide_emoji_icon === false ? '' : ' d-none'
                                }"
                        .defaulticons=${true}
                        .message=${o}
                        .messagemodel=${el.model}
                        .chatview=${el.chatboxviews}
                        style="margin-top: 3rem;">
                    </converse-emoji-dropdown>`
                              : ``}
                      </div>
                  </div>
              </div>`
        : html` ${o.is_first_unread
                  ? html` <div class="message separator d-none">
                        <hr class="separator" />
                        <span class="separator-text">${i18n_new_messages}</span>
                    </div>`
                  : ''}
              <div
                  class="d-flex"
                  @mouseenter=${ev => showEmojiContainer(ev, el)}
                  @mouseleave=${ev => hideEmojiContainer(ev, el)}
              >
                  <div class="w-100 chat-box" id="${o.msgid}">
                      <div
                          class="${o.sender === 'me'
                              ? 'sent-dracula'
                              : 'received-dracula left-in'} ${el.getExtraMessageClasses()}"
                          data-isodate="${o.time}"
                          data-msgid="${o.msgid}"
                          data-from="${o.from}"
                          data-encrypted="${o.is_encrypted}"
                      >
                          ${true
                              ? html` <div class="d-flex my-2 ${isMyMessage ? 'flex-row-reverse' : ''}">
                                ${
                                    o.sender !== 'me' && o.type === CHAT_TYPE.GROUP_CHAT
                                        ? html`${o.type}<a class="show-msg-author-modal" @click=${el.showUserModal}>
                                                  <converse-avatar
                                                      class="avatar align-self-center"
                                                      .data=${image_attributes}
                                                      nonce=${el.model.vcard?.get('vcard_updated')}
                                                      height="40"
                                                      width="40"
                                                  >
                                                  </converse-avatar>
                                              </a>`
                                        : ``
                                }    
                                
                                    <div class="d-flex flex-column">
                                        <div class="d-flex ${isMyMessage ? 'flex-row-reverse mr-2' : ''}">
                                        ${
                                            o.sender !== 'me' && o.type === CHAT_TYPE.GROUP_CHAT
                                                ? html` <span class="chat-msg__author ml-2"
                                                      >${o?.nick?.length > 20
                                                          ? o?.nick.substring(0, 25) + '...'
                                                          : o?.nick}</span
                                                  >`
                                                : ''
                                        }
                                            <time
                                                style="font-size: 11px; color: #F8F9F9; opacity: 50%;"
                                                timestamp="${el.model.get('edited') || el.model.get('time')}"
                                                class="chat-msg__time text-right ${isMyMessage ? 'mr-1' : 'ml-1'}"
                                                >${o.pretty_time}
                                            </time>
                                        </div>
                                        <div class="d-flex align-items-center position-relative ${
                                            isMyMessage ? 'flex-row-reverse' : ''
                                        }">
                                            <div
                                                class="message chat-msg d-inline-block py-2 px-3 ml-2 mt-2 ${
                                                    o.sender !== 'me'
                                                        ? 'background-them-dracula'
                                                        : 'background-me-dracula'
                                                }"
                                                style="border-radius: 30px"
                                            >
                                                ${msg_reply} ${is_forwarded} ${moment_reply}
                                                <!-- Anchor to allow us to scroll the message into view -->
                                                <a id="${o.msgid}"></a>
                                                <div
                                                    class="d-flex flex-column width-100-precent chat-msg__content chat-msg__content--${
                                                        o.sender
                                                    } ${o.is_me_message ? 'chat-msg__content--action' : ''}"
                                                    style="border-radius: 12px 12px 12px 12px;"
                                                >
                                                    <div
                                                        class="chat-msg__body chat-msg__body--${o.message_type} ${
                                    o.received ? 'chat-msg__body--received' : ''
                                } ${o.is_delayed ? 'chat-msg__body--delayed' : ''}"
                                                    >
                                                        <div class="chat-msg__message">
                                                            ${
                                                                o.is_me_message
                                                                    ? html` <time
                                                                              timestamp="${o.edited || o.time}"
                                                                              class="chat-msg__time"
                                                                              >${o.pretty_time}</time
                                                                          >&nbsp;
                                                                          <span class="chat-msg__author"
                                                                              >${o.is_me_message
                                                                                  ? '**'
                                                                                  : ''}${o.username}</span
                                                                          >&nbsp;`
                                                                    : ''
                                                            }
                                                            ${
                                                                o.is_retracted
                                                                    ? el.renderRetraction()
                                                                    : el.renderMessageText()
                                                            }
                                                        </div>
                                                    </div>
                                                    ${
                                                        !o.is_me_message && !is_followup
                                                            ? html` <span class="chat-msg__heading">
                                                                  <div>
                                                                      ${o.hats.map(
                                                                          h =>
                                                                              html`<span class="badge badge-secondary"
                                                                                  >${h.title}</span
                                                                              >`
                                                                      )}
                                                                  </div>
                                                                  <div class="d-flex">
                                                                      ${o.is_encrypted
                                                                          ? html`<converse-icon
                                                                                class="fa fa-lock align-self-center d-none"
                                                                                size="1.1em"
                                                                            ></converse-icon>`
                                                                          : ''}
                                                                  </div>
                                                              </span>`
                                                            : ''
                                                    }
                                                    ${el.model.get('ogp_metadata')?.map(m => {
                                                        if (el.model.get('hide_url_previews') === true) {
                                                            return '';
                                                        }
                                                        if (!shouldRenderMediaFromURL(m['og:image'], 'image')) {
                                                            return '';
                                                        }
                                                        return html` <converse-message-unfurl
                                                            @animationend="${el.onUnfurlAnimationEnd}"
                                                            class="${el.model.get('url_preview_transition')}"
                                                            jid="${el.chatbox?.get('jid')}"
                                                            description="${m['og:description'] || ''}"
                                                            title="${m['og:title'] || ''}"
                                                            image="${m['og:image'] || ''}"
                                                            url="${m['og:url'] || ''}"
                                                        >
                                                        </converse-message-unfurl>`;
                                                    })}
                                                    <converse-message-reactionview
                                                        .el=${el}
                                                        ?is_retracted=${o.is_retracted}
                                                    >
                                                    </converse-message-reactionview>
                                                </div>
                                            </div>
                                          
                                                
                                <converse-reaction-emoji-dropdown
                                    class="${
                                        o.show_emoji_icon_force ? `` : o.hide_emoji_icon === false ? '' : ' d-none'
                                    } position-absolute ${!isMyMessage ? 'ml-2' : ''}"
                                    .defaulticons=${true}
                                    .message=${o}
                                    .messagemodel=${el.model}
                                    .chatview=${el.chatboxviews}
                                    style="${isMyMessage ? "left: -120px;": "right: -130px;"}">
                                </converse-emoji-dropdown>
                                        </div>
                                    </div>
                                </div>`
                              : ''}
                      </div>
                  </div>
              </div>`;
};
