import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core.js";
import { html } from "lit";
import { STATUSES } from '../constants.js';
import { formatTime, getKeyByValue, isThemeDracula, capitalizeFirstLetter } from "../../../utils/helper.js";
import { MESSAGE_TYPE } from '@converse/headless/plugins/muclight/constants.js';

const tpl_remove_link = (el, item) => {
    const display_name = item.getDisplayName();
    const i18n_remove = __('Click to remove %1$s as a contact', display_name);
    return html`
       <a class="list-item-action remove-xmpp-contact" @click=${el.removeContact} title="${i18n_remove}" href="#">
          <converse-icon class="fa fa-trash-alt" size="1.5em"></converse-icon>
       </a>
       <a class="list-item-action" @click=${el.showOptions} title="${i18n_remove}" href="#">
          <converse-icon class="fa fa-angle-down" size="1.5em"></converse-icon>
       </a>
    `;
 }

const tpl_chat_extras = (el, item, num_unread, is_pinned) => {
    const display_name = item.getDisplayName();
    const i18n_remove = __('Click to remove %1$s as a contact', display_name);
    return html`<span class="d-flex">
        ${
            ''
            // <a class="list-item-action remove-xmpp-contact" @click=${el.removeContact} title="${i18n_remove}" href="#">
            //  <converse-icon class="fa fa-trash-alt" size="1.5em"></converse-icon>
            //</a>
        }
        ${num_unread ? html`<span class="msgs-indicator-hookzoffice">${num_unread}</span>` : ''}
        <span class="horizontal-spacing"></span>
        ${ is_pinned
            ? html`<span class="d-flex align-items-center">
                  <img src="/images/chat-sidebar/pin.svg" alt="pin" />
              </span>`
            : ''}
        <span class="horizontal-spacing"></span>
        <a class="list-item-action m-0" @click=${el.showOptions} title="${i18n_remove}" href="#">
            <converse-icon class="fa fa-angle-down" size="1.5em"></converse-icon>
        </a>
    </span> `;
};

export default  (el, item) => {
   const show = item.presence.get('show') || 'offline';
    let classes, color;
    if (show === 'online') {
        [classes, color] = ['fa fa-circle', 'chat-status-online'];
    } else if (show === 'dnd') {
        [classes, color] =  ['fa fa-minus-circle', 'chat-status-busy'];
    } else if (show === 'away') {
        [classes, color] =  ['fa fa-circle', 'chat-status-away'];
    } else {
        [classes, color] = ['fa fa-circle', 'subdued-color'];
    }
    const noProfilePic = "/images/general_assets/no_image_icon.png"
    // el.model.attributes
   const desc_status = STATUSES[show];
   let num_unread = item.get('num_unread') || 0;
   if(num_unread > 9){
    num_unread = '9+'
   }
   // const image_attributes = el.model?.attributes?.image?.length ? el.model.attributes : el.model.vcard?.attributes
   const image_attributes = el.model?.attributes
   const display_name = item.getDisplayName();
   const jid = el.model.get('jid');
   const i18n_chat = __('Click to chat with %1$s (XMPP address: %2$s)', display_name, jid);
   let lastMsg = el.model?.attributes?.last_msg;
   const lastMessageRecievedTime = formatTime(el.model?.attributes?.last_msg_time);
   const lastMsgType = el.model?.attributes?.last_msg_type;
   if(lastMsgType){
    lastMsg = capitalizeFirstLetter(getKeyByValue(MESSAGE_TYPE, lastMsgType));
   }

   const is_pinned = _converse.roster.get(jid)?.get('is_pinned');

   if (isThemeDracula()) {
       return html` <a
           class="list-item-link cbox-list-item open-chat d-flex justify-content-between px-2 px-xl-3 hookzoffice-roster-contact"
           title="${i18n_chat}"
           @click=${el.openChat}
           ><span class="d-flex w-100">
               <span>
                   <converse-avatar
                       class="avatar"
                       .data=${image_attributes}
                       nonce=${el.model.vcard?.get('vcard_updated')}
                   ></converse-avatar>
                   <converse-icon
                       title="${desc_status}"
                       color="var(--${color})"
                       size="1.2em"
                       class="${classes} chat-status chat-status--avatar"
                   ></converse-icon>
               </span>
               <span class="d-flex flex-column justify-content-center ml-1 ml-lg-2">
                   <span class="primary-font-size">${display_name}</span>
                   <span class="vertical-spacing"></span>
                   <span class="secondary-font-size d-flex align-items-center">
                       ${lastMsgType
                           ? html`<img
                                 src="/images/chat-sidebar/${lastMsg.toLowerCase()}.svg"
                                 alt=""
                                 class="mr-1 message-icon"
                             />`
                           : ''}
                       <span>${lastMsg}</span>
                   </span>
               </span>
           </span>
           <span class="d-flex flex-column justify-content-center last-span">
               ${lastMessageRecievedTime
                   ? html`<time>
                         <span class="secondary-font-size ${num_unread ? 'open-chat unread-msgs' : ''}"
                             >${lastMessageRecievedTime}</span
                         >
                     </time>`
                   : ''}
               <span class="vertical-spacing"></span>
               ${api.settings.get('allow_contact_removal') && !el?.data?.disable_trash
                   ? tpl_chat_extras(el, item, num_unread, is_pinned)
                   : ''}
           </span>
       </a>`;
   }

   return html`
   <a class="list-item-link cbox-list-item open-chat ${ num_unread ? 'unread-msgs' : '' }" title="${i18n_chat}" href="#" @click=${el.openChat}>
      <span>
         <converse-avatar
            class="avatar"
            .data=${image_attributes}
            nonce=${el.model.vcard?.get('vcard_updated')}
            height="50" width="50"></converse-avatar>
         <converse-icon
            title="${desc_status}"
            color="var(--${color})"
            size="1.2em"
            class="${classes} chat-status chat-status--avatar"></converse-icon>
      </span>
      ${ num_unread ? html`<span class="msgs-indicator">${ num_unread }</span>` : '' }
      <span class="contact-name contact-name--${el.show} ${ num_unread ? 'unread-msgs' : ''}">${display_name}</span>
   </a>
   ${ api.settings.get('allow_contact_removal') && !el?.data?.disable_trash ? tpl_remove_link(el, item) : '' }`;
}
