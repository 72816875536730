import { __ } from 'i18n';
import { html } from 'lit';
import { _converse } from '@converse/headless/core';
import { repeat } from 'lit/directives/repeat.js';
import moment from 'moment';
import '../../../../../styles/themes/wingtrill/light/modal/doctor-appointment.scss';

export default (el) => {
    if (!el?.doctor) return;
    const { uid, username, degree, email } = el.doctor;

    function renderDate(date, index) {
        return html`<button
        type="button"
        class="p-1 p-md-2 p-xl-3 ${index === el.selectedDateIndex ? 'active': ''}"
        ${(date.isDisabled || el.isFetchingAppointments) ? 'disabled': ''}
        @click=${() => {
            el.selectedDateIndex = index;
            el.fetchAppointments({ doctorId: uid, date: date.milliseconds.toString() });
        }}
      >
        ${date.date}
      </button>`
    }

    function renderAppointments() {
        return el.isFetchingAppointments ? html`<div class="my-3">Getting available appointments...</div>` : !el.appointments.length ? html`<div class="my-3">Sorry, no dates are available currently</div>` : html`<div><h3 class="my-3">Select appointment time: </h3><div class="appointment-time">${repeat(el.appointments, (appointment, index) => renderAppointment(appointment, index))}</div></div>`
    }

    function renderAppointment(appointment, index) {
        const { from, to, booked } = appointment;
        const momentFromObj = moment(from);
        const formattedFromTime = momentFromObj.format("hh.mm A");
        const momentToObj = moment(to);
        const formattedToTime = momentToObj.format("hh.mm A");
        return html`<button
              type="button"
              class="p-1 p-md-2 p-xl-3 ${index === el.selectedAppointmentIndex ? 'active': ''}"
              ${booked ? 'disabled' : ''}
              @click=${() => {
                el.selectedAppointmentIndex = index;
                el.render();
              }}
            >${formattedFromTime} - ${formattedToTime}</button>`;
    }

    function renderError(error) {
        return html`<div class="text-danger text-center my-3">${error}</div>`
    }

    function handleDone(){
        if(!Array.isArray(el.appointments)) return;
        const selectedAppointment = el.appointments.find((_appointment, index) => index === el.selectedAppointmentIndex);
        if(!selectedAppointment) return;
        const { from, to } = selectedAppointment;
        el.sendMessage({ question: el.question, answer: uid, slot: { from, to }});
        closeModal();
    }

    function closeModal() {
        el.modal.hide();
    }

    return html`<div class="p-3">
        ${repeat(el.errors, renderError)}
        <div>
            <span class="doctor-name">${username}</span><span class="text-muted" style="font-size: 20px;"> | </span><span class="text-muted">${degree}</span>
        </div>
        <div class="text-muted">${email}</div>
        ${el.isFetchingWeekDates ? html`<div class="my-3">Getting available dates...</div>` : !el.weekDates.length ? html`<div>Sorry, no dates are available currently</div>` : html`<div>
        <h3 class="my-3">Select appointment date: </h3>
        <div class="appointment-dates">${repeat(el.weekDates, (date, index) => renderDate(date, index))}</div>
        </div>`}
        ${el.isDateSelected ? renderAppointments() : html``}
        <div class="d-flex justify-content-end mt-3">
            <button class="py-2 px-4 mr-1" @click=${closeModal}>Cancel</button>
            ${!isNaN(el.selectedAppointmentIndex) ? html`<button class="py-2 px-4" @click=${handleDone}>Done</button>`: html``}
        </div>
    </div>`;
};
