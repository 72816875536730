import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import { html } from 'lit';
import tpl_message_reply from '../../message-reply.js';

import '../../../styles/bottom-panel-dracula.scss';

export default (o) => {
    const unread_msgs = __('You have unread messages');
    const message_limit = api.settings.get('message_limit');
    const show_call_button = api.settings.get('visible_toolbar_buttons').call;
    const show_emoji_button = api.settings.get('visible_toolbar_buttons').emoji;
    const show_send_button = api.settings.get('show_send_button');
    const show_spoiler_button = api.settings.get('visible_toolbar_buttons').spoiler;
    const show_toolbar = api.settings.get('show_toolbar');
    let reply_template = ``
    if(Object.entries(_converse.chatboxviews.views)[1] && o.model.get(`reply_msg_id`)!==null && o.model.get(`reply_msg_id`)!==``){
        reply_template = _converse.chatboxes.get(Object.entries(_converse.chatboxviews.views)[1][0]).messages.findWhere({msgid:o.model.get(`reply_msg_id`)}) ? tpl_message_reply(_converse.chatboxes.get(Object.entries(_converse.chatboxviews.views)[1][0]).messages.findWhere({msgid:o.model.get(`reply_msg_id`)}),_converse.chatboxes.get(Object.entries(_converse.chatboxviews.views)[1][0])) : ``
    }

    return html`<div>${reply_template}
        ${ o.model.ui.get('scrolled') && o.model.get('num_unread') ?
                html`<div class="new-msgs-indicator" @click=${ev => o.viewUnreadMessages(ev)}>▼ ${ unread_msgs } ▼</div>` : '' }
        ${(api.settings.get('show_toolbar') && Object.entries(_converse.chatboxviews.views)[1]) ? html`
            <converse-chat-toolbar
                class="chat-toolbar no-text-select position-absolute d-flex align-items-center"
                .model=${o.model}
                ?composing_spoiler="${o.model.get('composing_spoiler')}"
                ?show_call_button="${show_call_button}"
                ?show_emoji_button="${show_emoji_button}"
                ?show_send_button="${show_send_button}"
                ?show_spoiler_button="${show_spoiler_button}"
                ?show_toolbar="${show_toolbar}"
                message_limit="${message_limit}"></converse-chat-toolbar>` : '' }
        <converse-message-form jid="${o.model.get('jid')}" class="d-flex justify-content-center"></converse-message-form>
        </div>`;
}
