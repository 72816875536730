import { html } from 'lit';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import '../../../../styles/themes/wingtrill/light.scss';
import { logOut } from '../../../../../profile/utils';

export default () => {

    const openNewGroupModal = () => {
        document.querySelector('.show-add-muc-modal').click();
    }
    
    return html 
        `<div class="d-flex position-relative p-3 main-wrapper">
            <span class="d-flex align-items-center">
                <converse-avatar height="50px" width="50px"></converse-avatar>
                <span class="primary-heading ml-2">Aditya Kumar</span>
            </span>
            <div class="d-flex align-items-center">
                <span @click=${ (ev) => _converse.xmppstatus.save({chatmenu:!_converse.xmppstatus.get(`chatmenu`)})} type="button" class="d-flex justify-content-center align-items-center custom-box-shadow-last new px-3 px-xl-4 py-2 py-xl-3 mr-2">
                    <span>New</span>
                    <span class="ml-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.081" height="13" viewBox="0 0 19.081 19.081">
                            <g transform="translate(-323.348 -692.572)">
                                <g transform="translate(321.098 690.417)">
                                    <path d="M10.9,6H4.756A1.756,1.756,0,0,0,3,7.756V20.049A1.756,1.756,0,0,0,4.756,21.8H17.049A1.756,1.756,0,0,0,18.8,20.049V13.9" transform="translate(0 -1.319)" fill="none" stroke="#242424" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </g>
                                <path d="M19.835,3.282A1.583,1.583,0,0,1,22.073,5.52l-7.089,7.088L12,13.355l.746-2.985Z" transform="translate(319 690.646)" fill="none" stroke="#242424" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                    </span>
                </span>
                <span class="menu p-1 p-md-2 p-xl-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="24">
                        <circle cx="50" cy="20" r="5" fill="black" />
                        <circle cx="50" cy="50" r="5" fill="black" />
                        <circle cx="50" cy="80" r="5" fill="black" />
                    </svg>
                </span>
            </div>
            <div class="d-none position-absolute flex-column align-items-center chat-header-tooltip-list">
                <div class="chat-header-tooltip-list-triangle-up"></div>
                <div class="list-group  ">
                    <div class="d-none">
                        <converse-rooms-list></converse-rooms-list>
                    </div>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click=${openNewGroupModal}>
                        <span>New Group</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.967" viewBox="0 0 29.179 16.967">
                                <g id="Group_16592" data-name="Group 16592" transform="translate(0.125 0.127)">
                                    <g id="Group_16593" data-name="Group 16593" transform="translate(0 0)">
                                        <path id="Path_11385" data-name="Path 11385" d="M28.928,14.856c-.15.2-.326.35-.593.26s-.3-.291-.317-.538a4.791,4.791,0,0,0-7.461-3.838,1.86,1.86,0,0,0-.158.118c-.013.011-.018.032-.006.01.381.582.785,1.126,1.111,1.713a7.658,7.658,0,0,1,.913,3.429,1.807,1.807,0,0,1,0,.254.474.474,0,0,1-.458.45.453.453,0,0,1-.45-.422c-.072-.511-.093-1.033-.208-1.533a7.036,7.036,0,0,0-13.777.418c-.058.323-.064.655-.094.982-.034.366-.2.56-.472.555s-.463-.23-.452-.6a7.924,7.924,0,0,1,1.887-4.993c.054-.065.11-.128.175-.2a.786.786,0,0,0-.114-.119,4.63,4.63,0,0,0-4.715-.456A4.584,4.584,0,0,0,.948,14.139c-.024.177-.034.356-.047.534a.445.445,0,0,1-.46.463A.436.436,0,0,1,0,14.657a5.531,5.531,0,0,1,1.667-3.977A6.065,6.065,0,0,1,2.63,9.9c.341-.225.722-.391,1.111-.6a3.347,3.347,0,0,1-1.4-3A3.213,3.213,0,0,1,3.573,3.951,3.361,3.361,0,1,1,7.614,9.318c.162.074.315.131.454.211.35.2.691.417,1.042.615.065.036.191.058.235.022A8.337,8.337,0,0,1,12.2,8.631,4.588,4.588,0,0,1,9.862,4.58a4.442,4.442,0,0,1,1.5-3.38,4.612,4.612,0,1,1,5.379,7.425c.19.069.362.13.531.194a7.724,7.724,0,0,1,2.21,1.282.262.262,0,0,0,.381.021c.454-.294.93-.553,1.412-.835A3.333,3.333,0,0,1,19.9,6.3a3.229,3.229,0,0,1,1.085-2.227,3.367,3.367,0,1,1,4.2,5.25c.127.057.244.106.359.161a5.649,5.649,0,0,1,3.334,4.421.921.921,0,0,0,.054.155ZM10.778,4.592A3.684,3.684,0,1,0,14.487.915a3.691,3.691,0,0,0-3.708,3.677m14.9,1.971a2.442,2.442,0,0,0-4.884-.029,2.449,2.449,0,0,0,2.433,2.451,2.422,2.422,0,0,0,2.451-2.423M3.249,6.537a2.442,2.442,0,0,0,4.884.037,2.442,2.442,0,0,0-4.884-.037" transform="translate(0 0.001)" fill="#003254" stroke="#003356" stroke-width="0.25"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between">
                        <span>Explore</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13" height="18" viewBox="0 0 18 18">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_5533" data-name="Rectangle 5533" width="18" height="18" transform="translate(0 0)" fill="#003357"/>
                                    </clipPath>
                                </defs>
                                <g id="Group_16504" data-name="Group 16504" transform="translate(-0.066 -0.066)">
                                    <g id="Group_16504-2" data-name="Group 16504" transform="translate(0.066 0.066)" clip-path="url(#clip-path)">
                                        <path id="Path_11325" data-name="Path 11325" d="M8.333,4.168c0,.644,0,1.287,0,1.931A2.226,2.226,0,0,1,6.1,8.334q-1.931.007-3.861,0A2.226,2.226,0,0,1,0,6.089q0-1.92,0-3.84A2.225,2.225,0,0,1,2.241,0Q4.172,0,6.1,0a2.226,2.226,0,0,1,2.23,2.234c0,.644,0,1.287,0,1.931M4.167,6.948c.615,0,1.23,0,1.844,0a.866.866,0,0,0,.934-.934q0-1.845,0-3.689a.864.864,0,0,0-.934-.933q-1.844,0-3.689,0a.864.864,0,0,0-.933.934q0,1.845,0,3.689a.865.865,0,0,0,.934.934c.615,0,1.23,0,1.844,0" transform="translate(-0.059 -0.059)" fill="#003357"/>
                                        <path id="Path_11326" data-name="Path 11326" d="M.01,171.862c0-.644,0-1.287,0-1.931a2.226,2.226,0,0,1,2.207-2.212q1.952-.008,3.9,0a2.223,2.223,0,0,1,2.218,2.223q.007,1.942,0,3.884a2.224,2.224,0,0,1-2.218,2.223q-1.941.008-3.883,0A2.227,2.227,0,0,1,.01,173.815c0-.651,0-1.3,0-1.953m4.179,2.8c.608,0,1.215,0,1.823,0a.868.868,0,0,0,.943-.947q0-1.834,0-3.667a.867.867,0,0,0-.947-.942q-1.834,0-3.667,0a.866.866,0,0,0-.942.947q0,1.834,0,3.667a.867.867,0,0,0,.947.943c.615,0,1.23,0,1.844,0" transform="translate(-0.067 -158.051)" fill="#003357"/>
                                        <path id="Path_11327" data-name="Path 11327" d="M167.707,171.884c0-.651,0-1.3,0-1.953a2.226,2.226,0,0,1,2.207-2.213q1.952-.008,3.9,0a2.223,2.223,0,0,1,2.218,2.223q.007,1.942,0,3.884a2.224,2.224,0,0,1-2.217,2.224q-1.941.008-3.883,0a2.227,2.227,0,0,1-2.23-2.234c0-.644,0-1.287,0-1.931m4.161,2.779q.911,0,1.823,0a.872.872,0,0,0,.96-.952q0-1.823,0-3.646a.871.871,0,0,0-.952-.96q-1.823,0-3.645,0a.87.87,0,0,0-.96.951q0,1.823,0,3.646a.87.87,0,0,0,.951.96c.608,0,1.215,0,1.823,0" transform="translate(-158.042 -158.051)" fill="#003357"/>
                                        <path id="Path_11328" data-name="Path 11328" d="M171.9,8.368A4.166,4.166,0,1,1,176.058,4.2,4.157,4.157,0,0,1,171.9,8.368M174.67,4.2a2.778,2.778,0,1,0-2.776,2.776A2.8,2.8,0,0,0,174.67,4.2" transform="translate(-158.061 -0.092)" fill="#003357"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between">
                        <span>Starred Messages</span>
                        <span>    
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17.611" viewBox="0 0 18.965 17.611">
                                <path id="Icon_ionic-ios-star-outline" data-name="Icon ionic-ios-star-outline" d="M20.5,9.471H14.268L12.376,3.824a.686.686,0,0,0-1.287,0L9.2,9.471H2.927a.679.679,0,0,0-.677.677.5.5,0,0,0,.013.114.651.651,0,0,0,.284.478l5.118,3.607L5.7,20.058a.679.679,0,0,0,.233.762.655.655,0,0,0,.381.165.83.83,0,0,0,.423-.152l5-3.56,5,3.56a.793.793,0,0,0,.423.152.608.608,0,0,0,.377-.165.671.671,0,0,0,.233-.762L15.8,14.348l5.076-3.641L21,10.6a.71.71,0,0,0,.22-.453A.717.717,0,0,0,20.5,9.471Zm-5.385,3.916a1.189,1.189,0,0,0-.432,1.346l1.274,3.713a.17.17,0,0,1-.258.195L12.418,16.3a1.183,1.183,0,0,0-.69-.22,1.161,1.161,0,0,0-.686.22L7.766,18.636a.169.169,0,0,1-.258-.195l1.274-3.713a1.193,1.193,0,0,0-.436-1.355L4.917,10.957a.17.17,0,0,1,.1-.309H9.193a1.184,1.184,0,0,0,1.122-.809l1.253-3.734a.17.17,0,0,1,.322,0l1.253,3.734a1.184,1.184,0,0,0,1.122.809h4.119a.167.167,0,0,1,.1.3Z" transform="translate(-2.25 -3.375)" fill="#003254"/>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click=${logOut}>
                        <span>${__('Log out')}</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15.281" viewBox="0 0 15.281 15.281">
                                <g id="Icon_feather-log-out" data-name="Icon feather-log-out" transform="translate(-3.875 -3.875)">
                                    <path id="Path_11386" data-name="Path 11386" d="M9.177,18.531H6.059A1.559,1.559,0,0,1,4.5,16.972V6.059A1.559,1.559,0,0,1,6.059,4.5H9.177" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                    <path id="Path_11387" data-name="Path 11387" d="M24,18.295l3.9-3.9L24,10.5" transform="translate(-9.367 -2.882)" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                    <path id="Path_11388" data-name="Path 11388" d="M22.854,18H13.5" transform="translate(-4.323 -6.485)" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                </g>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>`
};