import tpl_root from "./templates/root.js";
import tpl_root_dracula from "./templates/root_dracula.js";
import tpl_root_wingtrill_light from "./templates/themes/wingtrill/light/root.js";
import tpl_root_wingtrill_dark from "./templates/themes/wingtrill/dark/root.js";
import { _converse, api } from '@converse/headless/core';
import { CustomElement } from 'shared/components/element.js';
import { getAppSettings } from '@converse/headless/shared/settings/utils.js';
import { getTheme } from './utils.js';

import './styles/root.scss';
import { getCurrentTheme } from "../../utils/helper.js";
import { BOT_JID, THEMES } from "../../shared/constants.js";
import { getNewToken, openBotChatBox } from "@converse/headless/plugins/chat/utils.js";

/**
 * `converse-root` is an optional custom element which can be used to
 * declaratively insert the Converse UI into the DOM.
 *
 * It can be inserted into the DOM before or after Converse has loaded or been
 * initialized.
 */
export default class ConverseRoot extends CustomElement {

    initialize () {
        api.waitUntil('rosterContactsFetched').then( () => {
            this.model = _converse.workstation || []
            this.modeldata = _converse.workstationdata || []
            this.listenTo(this.model, 'add', () => this.requestUpdate());
            this.listenTo(this.model, 'destroy', () => this.requestUpdate());
            this.listenTo(this.model, 'remove', () => this.requestUpdate());
            this.listenTo(this.modeldata, 'change:selected_workstation', () =>  this.requestUpdate());
            this.requestUpdate();
            openBotChatBox();
            api.trigger('updateChatPopups', { jid: BOT_JID });
            setInterval(() => {
                getNewToken();
            }, 59 * 60 * 1000);
        });
        this.setAttribute('id', 'conversejs');
        this.setClasses();
        const settings = getAppSettings();
        this.listenTo(settings, 'change:view_mode', () => this.setClasses())
        this.listenTo(settings, 'change:singleton', () => this.setClasses())
        api.listen.on('statusInitialized', () => this.requestUpdate());
        api.listen.on('allWorkstationsData', () => this.requestUpdate());
        api.listen.on('showUserAccountDropdown', (showUserAccountDropdown) => {
            this.showUserAccountDropdown = showUserAccountDropdown;
            this.requestUpdate()
        });
        api.listen.on('showPauseNotificationsDropdown', (showPauseNotificationsDropdown) => {
            this.showPauseNotificationsDropdown = showPauseNotificationsDropdown;
            this.requestUpdate();
        });
        api.listen.on('showAllUserAccountsDropdown', (showAllUserAccountsDropdown) => {
            this.showAllUserAccountsDropdown = showAllUserAccountsDropdown;
            this.requestUpdate();
        });
        window.matchMedia('(prefers-color-scheme: dark)').addListener(() => this.setClasses());
        window.matchMedia('(prefers-color-scheme: light)').addListener(() => this.setClasses());
    }

    render() {
        // eslint-disable-line class-methods-use-this
        const { WINGTRILL_LIGHT, WINGTRILL_DARK, DRACULA } = THEMES;
        let template;
        switch (getCurrentTheme()) {
            case WINGTRILL_LIGHT:
                template = tpl_root_wingtrill_light(this);
                break;

            case WINGTRILL_DARK:
                template = tpl_root_wingtrill_dark(this);
                break;

            case DRACULA:
                template = tpl_root_dracula(this);
                break;

            default:
                template = tpl_root();
        }
        return template;
    }

    setClasses () {
        this.className = "";
        this.classList.add('conversejs');
        this.classList.add(`converse-${api.settings.get('view_mode')}`);
        this.classList.add(`theme-${getTheme()}`);
        this.classList.add(`d-flex`);
        this.classList.add(`flex-column`);
        this.requestUpdate();
    }
}
